import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { noop } from 'lodash';
import { ControlLabel, FormGroup } from 'react-bootstrap';

import { createInputContainer } from '../../containers';
import { IFormInput, IInputContainer, Value } from '../../interfaces';

interface IPropDefaults extends IFormInput {
  onChange: (value: Value) => void;
}

interface IPropsFormsy extends IPropDefaults, IInputContainer {}

@createInputContainer
@autoBindMethods
@observer
class Checkbox extends Component<IFormInput, {}> {
  @observable private value: boolean;

  public static defaultProps: Partial<IFormInput> = {
    onChange: noop,
  };

  get formsy () {
    return this.props as IPropsFormsy;
  }

  constructor (props: IPropsFormsy) {
    super(props);

    this.value = !!props.value;
    this.setValue();
    props.onChange(this.value);
  }

  private setValue () {
    this.formsy.setValue(this.value);
  }

  private onChange (event: any) {
    const { onChange } = this.formsy;

    this.value = event.target['checked'];
    this.setValue();
    onChange(this.value);
  }

  public render () {
    const {
      controlId,
      field,
      formGroupId,
      isDisabled,
      label,
    } = this.formsy
    , className = cx(
      'form-group-checkbox',
      'original-styling',
      {'has-value': this.value},
    );

    return (
      <FormGroup id={formGroupId} controlId={controlId} className={className}>
        <input
          className='form-control'
          checked={this.value || false}
          disabled={isDisabled}
          id={controlId}
          name={field}
          onChange={this.onChange}
          type='checkbox'
        />

        {label &&
        <ControlLabel>
          {label}
        </ControlLabel>}
      </FormGroup>
    );
  }
}

export default Checkbox;
