/* tslint:disable no-magic-numbers object-literal-sort-keys */
import { CSSTransitionGroupProps } from 'react';
import { fromPairs, invert, map } from 'lodash';
import Decimal from 'decimal.js';

const APPLICATION_FACETS_API: { [key: string]: string } = {
  medical_facility: 'medical_lien_summary__medical_facility__name',
  medical_provider: 'medical_lien_summary__medical_provider',
}
  , APPLICATION_FACETS_DISPLAY = invert(APPLICATION_FACETS_API)
  , CASE_MEDICAL_STATUS: { [key: string]: string } = {
    plaintiff_treating: 'Plaintiff treating',
    plaintiff_done_treating: 'Plaintiff done treating',
  }
  , CASE_TRACKING_ATTRIBUTES: { [key: string]: string } = {
    attorney_no_longer_represents_plaintiff: 'Attorney no longer represents plaintiff',
    attorney_never_represented_plaintiff: 'Attorney never represented plaintiff',
    attorney_not_responsive: 'Attorney not responsive',
    awaiting_check: 'Awaiting check',
    collections: 'Collections',
    no_update: 'No update since last time',
    parallel_plaintiff_funding_identified: 'Parallel plaintiff funding lien identified',
    plaintiff_deceased: 'Plaintiff deceased',
    plaintiff_filed_for_bankruptcy: 'Plaintiff filed for bankruptcy',
    plaintiff_incarcerated: 'Plaintiff incarcerated',
    plaintiff_was_paid: 'Plaintiff was paid',
    potential_loss: 'Potential loss',
    reduction_requested: 'Reduction requested',
  }
  , CASE_TRACKING_STATUS: { [key: string]: string } = {
    pre_demand: 'Pre-demand',
    demand_sent: 'Demand sent',
    lawsuit_filed: 'Lawsuit filed',
    mediation_or_arbitration_scheduled: 'Mediation or arbitration scheduled',
    trial_scheduled: 'Trial scheduled',
    case_lost: 'Case lost',
    case_settled: 'Case Settled',
    compiling_medical_records: 'Compiling medical records',
    negotiations: 'Negotiations',
    case_closed_without_settlement: 'Case closed without settlement',
  }
  , CASE_CLOSED_STATUSES: { [key: string]: string } = {
    closed_full_payment: 'Closed - Full payment',
    closed_reduced_payment: 'Closed - Reduced payment',
    closed_partial_loss: 'Closed - Partial loss',
    closed_no_recovery: 'Closed - No recovery',
  }
  , CASE_LABELS: { [key: string]: string } = {
    ...CASE_MEDICAL_STATUS,
    ...CASE_TRACKING_STATUS,
    ...CASE_TRACKING_ATTRIBUTES,
    ...CASE_CLOSED_STATUSES,
  }
  , APPLICATION_MODELS: { [key: string]: {
      key: string,
      model: string,
      label: string,
      icon: string,
    }} = {
      LEGAL_FUNDING: {
        key: 'LEGAL_FUNDING',
        model: 'applications.legalfundingapplication',
        label: 'Plaintiff',
        icon: 'user',
      },
      MEDICAL: {
        key: 'MEDICAL',
        model: 'applications.medicalfundingapplication',
        label: 'Medical',
        icon: 'medkit',
      },
    }
  , PRINCIPAL_PARTY_TYPES: { [key: string]: string } = {
    PLAINTIFF_FUNDER: 'plaintiff-funder',
    MEDICAL_FUNDER: 'medical-funder',
    MEDICAL_PROVIDER: 'medical-provider',
    GENERIC_LIENHOLDER: 'generic-lienholder',
  }
  , FUNDING_TYPES: { [key: string]: string } = {
    PLAINTIFF: 'plaintiff',
    MEDICAL: 'medical',
    GENERIC: 'generic',
  }
  , FUNDING_TYPE_LABELS: { [key: string]: string } = {
    [FUNDING_TYPES.PLAINTIFF]: 'Plaintiff Funding',
    [FUNDING_TYPES.MEDICAL]: 'Medical',
    [FUNDING_TYPES.GENERIC]: '',
  }
  , LIEN_MODELS: { [key: string]: {
      application_key?: string,
      endpoint: string,
      funding_type: string,
      icon: string,
      key: string,
      label: string,
      model: string,
    }} = {
      LEGAL_FUNDING: {
        key: 'LEGAL_FUNDING',
        model: 'liens.legalfundinglien',
        funding_type: FUNDING_TYPES.PLAINTIFF,
        label: 'Legal Funding',
        endpoint: 'legal-funding-liens',
        icon: 'user',
        application_key: APPLICATION_MODELS.LEGAL_FUNDING.key,
      },
      LEGACY: {
        key: 'LEGACY',
        model: 'liens.legacylien',
        funding_type: FUNDING_TYPES.PLAINTIFF,
        label: 'Legal Funding',
        endpoint: 'legacy-liens',
        icon: 'user',
      },
      MEDICAL: {
        key: 'MEDICAL',
        model: 'liens.medicallien',
        funding_type: FUNDING_TYPES.MEDICAL,
        label: 'Medical',
        endpoint: 'medical-liens',
        icon: 'medkit',
        application_key: APPLICATION_MODELS.MEDICAL.key,
      },
      GENERIC: {
        key: 'GENERIC',
        model: 'liens.genericlien',
        funding_type: FUNDING_TYPES.GENERIC,
        label: '',
        endpoint: 'generic-liens',
        icon: 'file-o',
      },
    }
  , LIEN_MODELS_BY_FUNDING_TYPE = fromPairs(Object.keys(FUNDING_TYPES).map(typeKey => {
    const fundingType = FUNDING_TYPES[typeKey]
      , lienModels = Object.keys(LIEN_MODELS)
        .filter(lienModelKey => LIEN_MODELS[lienModelKey].funding_type === fundingType)
        .map(lienModelKey => LIEN_MODELS[lienModelKey].model);

    return [fundingType, lienModels];
  }))
  , LIEN_MODELS_BY_MODEL_NAME = fromPairs(map(LIEN_MODELS, (model) => [model.model, {...model}]))
  , MIGHTY_SERVICE_TIERS: { [key: string]: string | null } = {
    free: 'FREE',
    paid: 'PAID',
  }
  , MODEL_TYPES: { [key: string]: { key: string, display?: string, shortDisplay?: string }} = {
    attorney: { key: 'attorneys.attorney', display: 'Attorney' },
    capitalProvider: { key: 'capitalproviders.capitalprovider', display: 'Capital Provider' },
    caseGeneratedDocxActivity: { key: 'activities.casegenerateddocxactivity', display: 'Generated Documents' },
    caseNote: { key: 'activities.casenote', display: 'Notes' },
    case : { key: 'cases.case', display: 'Cases', shortDisplay: 'Case' },
    caseDocumentsSharedToRegistry: { key: 'activities.casedocumentssharedtoregistry', display: 'Documents Shared' },
    caseSms: { key: 'activities.casesms', display: 'SMS' },
    caseTrackingUpdate: { key: 'activities.casetrackingupdate', display: 'Case Tracking Updates' },
    caseTrackingUpdateRequest: { key: 'activities.casetrackingupdaterequest', display: 'Case Tracking Update Request' },
    contact: { key: 'contacts.contact', display: 'Contact' },
    deletedCaseDocument: { key: 'activities.deletedcasedocument', display: 'Deleted Documents' },
    deletedLienDocument: { key: 'activities.deletedliendocument', display: 'Deleted Lien Documents' },
    documentsShared: { key: 'activities.documentsshared', display: 'Documents Shared' },
    documentSharingRequest: { key: 'activities.documentsharingrequest', display: 'Document Sharing Request' },
    documentSharingTask: { key: 'tasks.documentsharingtask', display: 'Document Sharing Task' },
    generatedEmail: { key: 'activities.generatedemail', display: 'Emails' },
    lawFirm: { key: 'lawfirms.lawfirm', display: 'Lawfirm' },
    lienAcknowledgment: { key: 'activities.lienacknowledgment', display: 'Lien Acknowledgment' },
    lienDeleted: { key: 'activities.liendeleted', display: 'Lien Deleted' },
    lienReturnAction: { key: 'activities.lienreturnaction', display: 'Payments' },
    medicalCode: { key: 'medicalfacilities.medicalcode', display: 'Medical Code' },
    medicalFacility: { key: 'medicalfacilities.medicalfacility', display: 'Medical Facility' },
    medicalFacilityContact: { key: 'medicalfacilities.medicalfacilitycontact', display: 'Medical Facility Contact' },
    medicalProvider: { key: 'medicalfacilities.medicalprovider', display: 'Medical Provider' },
    partyOwnedLawFirm: { key: 'lawfirms.partyownedlawfirm', display: 'Lawfirm' },
    plaintiff: { key: 'plaintiffs.plaintiff', display: 'Plaintiff' },
    representativeAssignment: { key: 'activities.representativeassignment', display: 'Owner changes' },
    sentFax: { key: 'activities.sentfax', display: 'Sent Faxes' },
    verifiedLawFirm: { key: 'lawfirms.verifiedlawfirm' },
  }
  , ACCEPTED_INPUT_TYPES: { [key: string]: string } = {
    email: 'email',
    hidden: 'hidden',
    money: 'number',
    number: 'number',
    password: 'password',
    text: 'text',
  }

  , HIJACK_QUERY_PARAMS = ['impersonate_jwt', 'impostor']

  , ACTIVITY_STATUS_CLASSNAMES: { [key: string]: string } = {
    delivered: 'text-green',
    failed: 'text-red',
  }

  , APP_UPDATE_CHECK_INTERVAL = 1 * 60 * 1000 // 1 min
  , DOCUMENT_CONVERT_CHECK_INTERVAL = 3 * 1000 // 3 sec
  , EXPORT_CHECK_INTERVAL = 10 * 60 * 1000 // 10 min
  , IMPORT_CHECK_INTERVAL = 5 * 1000 // 5 sec
  , REPORT_EXPORT_CHECK_INTERVAL = 4 * 1000 // 4 sec
  , REPORT_EXPORT_COOKIE_TIMEOUT_INTERVAL = 10 * 60 * 1000 // 10 min

  , REPORT_EXPORT_COOKIE_NAME = 'report-async-download'
  , ASYNC_DOWNLOAD_STATE = { COMPLETE: 'COMPLETE', IN_PROGRESS: 'IN_PROGRESS', NEW: 'NEW', FAILED: 'FAILED' }

  , APPLICATION_TRANSITION_REASON_SHORT_CODES: { [key: string]: string } = {
    OTHER: 'other',
  }

  , EXPORT_STATUS: {[key: string]: string} = {
    COMPLETE: 'Complete',
    FAILED: 'Failed',
    IN_PROGRESS: 'In progress',
    NEW: 'Started',
  }

  , EXPORT_TYPES: {[key: string]: string} = {
    DOCUMENTS_ZIP: 'Documents',
    GENERIC_LIEN_CSV: 'Funding data',
    MEDICAL_LIEN_CSV: 'Medical funding data',
    PLAINTIFF_LIEN_CSV: 'Plaintiff funding data',
  }

  , LIEN_TYPES: { [key: string]: string } = {
    'generic': 'liens.genericlien',
    'medical': 'liens.medicallien',
    'legal-funding': 'liens.legalfundinglien',
    'legacy': 'liens.legacylien',
  }

  , LIEN_TYPES_LOOKUP: { [key: string]: string } = {
    'liens.genericlien': 'generic',
    'liens.legacylien': 'legacy',
    'liens.legalfundinglien': 'legal-funding',
    'liens.medicallien': 'medical',
  }

  , EMPTY_FIELD = '--'
  , REPRESENTATIVES_UNASSIGNED = 'unassigned'
  , NO_CHANGE_FIELD = 'No change'

  , APPLICATION_STATUS: { [key: string]: string } = {
    new: 'New',
    processing: 'Processing',
    underwriting: 'Underwriting',
    approved: 'Approved',
    funded: 'Funded',
    rejected: 'Rejected',
  }

  , OPEN_APPLICATION_STATES: Array<{ name: string, value: string }> = [
    {name: 'New', value: 'new'},
    {name: 'Processing', value: 'processing'},
    {name: 'Underwriting', value: 'underwriting'},
    {name: 'Approved', value: 'approved'},
  ]

  , REGISTRY_SHARING_ELIGIBILITY_STATUSES: { [key: string]: string } = {
    ineligible: 'Ineligible',
    ineligible_missing_data: 'Missing data',
    ineligible_pending_verification: 'Pending verification',
    eligible_private: 'Private',
    shared_pending_migration: 'Share in progress',
    shared: 'Shared',
  }

  , REGISTRY_SHARING_ELIGIBILITY_OPTIONS: Array<{ name: string, value: string }> =
    Object.entries(REGISTRY_SHARING_ELIGIBILITY_STATUSES).map(s => ({name: s[1], value: s[0]}))

  , BOOLEAN_FILTER_OPTIONS: Array<{ name: string, value: string }> = [
    {name: 'Yes', value: 'true'},
    {name: 'No', value: 'false'},
  ]

  , DATE_FORMATS: { [key: string]: string } = {
    date: 'MM/DD/YY',
    date_at_time: 'MM/DD/YY @ h:mmA', // ex. 07/14/16 @ 2:24PM
    date_edit: 'MM/DD/YYYY',
    date_value: 'YYYY-MM-DD',
  }

  , DEFENDANT_NAME_LIMIT = 300

  , LIEN_TYPE_LIMIT = 30

  , MAX_ATTACHMENTS_SIZE_MB = 25
  , BYTES_BASE = 1024

  , PLAINTIFF_NAME_LIMIT = 30

  , ROUTING: { [key: string]: string } = {
    admin: '/backend/command',
    analytics: '/analytics',
    applications: '/applications',
    attorneyList: '/contacts/attorneys',
    caseAddContact: '/add-contact',
    caseCreateContact: '/create-contact',
    caseTracking: '/case-tracking',
    caseUpdate: '/case-update',
    caseUpdateActionDefault: '/add',
    dataAdmin: '/data-admin',
    deduplication: '/data-admin/deduplication',
    exports: '/data-admin/exports',
    facilitiesList: '/contacts/facilities',
    forgotPassword: '/accounts/forgot-password',
    funderStaffAdmin: '/backend/command/funders/funderstaff',
    help: '/help',
    impersonate: '/impersonate',
    imports: '/data-admin/imports',
    lawfirmList: '/contacts/lawfirms',
    login: '/accounts/login',
    logout: '/accounts/logout',
    myAccount: '/accounts/my-account',
    openApplications: '/applications/open',
    organizations: '/contacts/organizations',
    peopleList: '/contacts/people',
    providerList: '/contacts/providers',
    rejectedApplications: '/applications/rejected',
    reports: '/reports',
    tasks: '/tasks',
    updates: '/updates',
  }

  , TOKEN: { [key: string]: number } = {
    EXPIRATION_DELTA_MS: 24 * 60 * 60 * 1000, // 24 hours
    REFRESH_DELTA_MS: 22 * 60 * 60 * 1000, // 22 hours
    VALIDITY_CHECK_MS: 12 * 60 * 60 * 1000, // 12 hours
  }

  , CENT = 100
  , CENT_DECIMAL = new Decimal('100')
  , INTEREST_RATE_DECIMAL_PLACES = 4 // 00.0000-99.9999

  , DEBOUNCE_DELAY = 300 // ms

  , KEYBOARD_ENTER_CODE = 13

  , LIEN_ACTIONS: { [key: string]: string } = {
    create: 'create',
    view: 'view',
    edit: 'edit',
    finalize: 'finalize',
    revise: 'revise',
  }

  , LIEN_FEE_TYPE: { [key: string]: string } = {
    application: 'Application',
    processing: 'Processing',
    attorney_review: 'Attorney Review',
    broker: 'Broker',
    other: 'Other',
  }

  , MONEY_DECIMAL_PLACES = 2

  , MONTHS_PER_YEAR = 12

  , SUPPORT_EMAIL = 'support@mighty.com'

  , TRANSITION_SLIDE: CSSTransitionGroupProps = {
    component: 'div',
    transitionEnterTimeout: 500,
    transitionLeave: true,
    transitionLeaveTimeout: 500,
    transitionName: 'slide',
  }

  , TABLE_SUMMARY_ITEM_COUNT = 3

  , DEFAULT_TRUNCATION_LENGTH = 100
  , ACTIVITY_TRUNCATE_LENGTH = 120
  , ACTIVITY_FILE_NAME_TRUNCATE_LENGTH = 30
  , TABLE_LONG_TEXT_TRUNCATION_LENGTH = 250
  , TRUNCATE_OBJECT_SEARCH = 60

  , TOOLTIP_ID_TABLE = 'tooltip-table'

  , LAYOUT: { [key: string]: object } = {
    COL6: { lg: 6, md: 6, sm: 6, xs: 6 },
  }

  , GOOGLE_WEBFONTS_URL = '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js'

  , INTAKE_WIDGET_SUBMISSION_EVENT = 'mighty.widget.submission'

  , EXTERNAL_URL_PROPS = { rel: 'noopener noreferrer', target: '_blank' }

  , USER_FLOW: { [key: string]: string } = {
    EVENT_TYPE: 'USER_FLOW',
    FROM_APPLICATIONS_PAGE: 'FROM_APPLICATIONS_PAGE',
    FROM_APPLICATIONS_SIDEBAR: 'FROM_APPLICATIONS_SIDEBAR',
    FROM_CASE_DETAIL_PAGE: 'FROM_CASE_DETAIL_PAGE',
    FROM_CASE_TRACKING_PAGE: 'FROM_CASE_TRACKING_PAGE',
    FROM_COMPLETED_TASK_RESPONSE: 'FROM_COMPLETED_TASK_RESPONSE',
    FROM_CONTACT_LIST_PAGE: 'FROM_CONTACT_LIST_PAGE',
    FROM_LAWFIRM_LIST_PAGE: 'FROM_LAWFIRM_LIST_PAGE',
    FROM_PERFORMANCE_PAGE: 'FROM_PERFORMANCE_PAGE',
    FROM_PORTFOLIO_PAGE: 'FROM_PORTFOLIO_PAGE',
    FROM_SEARCH_BAR: 'FROM_SEARCH_BAR',
    FROM_SEARCH_RESULTS: 'FROM_SEARCH_RESULTS',
  }

  , PERMISSIONS: { [key: string]: string } = {
    ADD_FUNDER_DATA_EXPORT: 'exports.add_funderdataexport',
    ADD_SELF_IMPORT: 'imports.add_selfimport',
    ADD_SMS_SAVED_REPLY: 'documents.add_smssavedreply',
    CLOSE_LIEN: 'liens.close_lien',
    CHANGE_APP_STATUS_TO_APPROVED: 'applications.change_status_to_approved',
    CHANGE_APP_STATUS_TO_FUNDED: 'applications.change_status_to_funded',
    CHANGE_DEDUPE: 'dedupe.change_dedupematch',
    CHANGE_FUNDER: 'funders.change_funder',
    CHANGE_LEGAL_FUNDING_LIEN: 'liens.change_legalfundinglien',
    CREATE_CASE_SMS: 'activities.add_casesms',
    CREATE_CASE_TRACKING_UPDATE_REQUEST: 'tasks.add_casetrackingupdatetask',
    DELETE_CASE: 'cases.delete_case',
    DELETE_CASE_DOCUMENTS: 'documents.delete_casedocument',
    DELETE_LIEN: 'liens.delete_lien',
    DELETE_LIEN_DOCUMENTS: 'documents.delete_liendocument',
    DELETE_LIEN_RETURN: 'returns.delete_lienreturn',
    DELETE_SMS_SAVED_REPLY: 'documents.delete_smssavedreply',
    DELETE_MEDICAL_APPLICATION: 'applications.delete_medicalfundingapplication',
    DELETE_PLAINTIFF_APPLICATION: 'applications.delete_legalfundingapplication',
    EDIT_CAPITAL_PROVIDER: 'capitalproviders.add_capitalfunding',
    EDIT_CASE_DOCUMENTS: 'documents.change_casedocument',
    EDIT_LIEN_DOCUMENTS: 'documents.change_liendocument',
    GENERATE_CONTRACT: 'liens.add_legalfundinglien',
    LOG_PAYMENT: 'returns.add_lienreturn',
    VIEW_ANALYTICS: 'reports.view_analytics',
    VIEW_CASE_TRACKING: 'funders.view_case_tracking',
    VIEW_REPORTS: 'reports.view_reports',
    VIEW_TRANCHES: 'coop.view_cooptranche',
  }

  , GROUPS: { [key: string]: string } = {
    ACCOUNTANT: 'Accountant',
    ADMIN: 'Admin',
    CASE_MANAGER: 'Case Manager',
    EXECUTIVE: 'Executive',
    SALES_REP: 'Sales Rep',
    SERVICER: 'Servicer',
    UNDERWRITER: 'Underwriter',
  }

  , REPRESENTATIVE_ASSIGNMENTS: string[] = [
    'Case Manager', 'Underwriter', 'Servicer', 'Sales Rep', 'Accountant', 'Admin', 'Executive',
  ]

  , CHART_COLORS: string[] = ['#b46ac9', '#59c6ce', '#6dd89d', '#f07ba6']

  , CASE_MAIN_TABS: { [key: string]: string } = {
    DETAILS: 'details',
    APPLICATIONS: 'applications',
    FUNDINGS: 'fundings',
    DOCUMENTS: 'documents',
    CONTACTS: 'contacts',
    UNDERWRITING: 'underwriting',
    PORTAL: 'portal',
  }

  , CASE_ASIDE_TABS: { [key: string]: string } = {
    FEED: 'feed',
    EMAIL: 'email',
    SMS: 'sms',
  }

  , WELL_COL_LABEL = 4
  , WELL_COL_VALUE = 8

  , S3_STATIC_BUCKET_URL = 'https://s3.amazonaws.com/mighty-v2-static'

  , NO_PERMISSION_TEXT = 'You do not have permission to take this action. Please contact your account administrator.'

  , APPLICATION_WORKFLOW_CONFIG: { [key: string]: string } = {
    REQUIRED: 'REQUIRED',
    DISABLED: 'DISABLED',
    OPTIONAL: 'OPTIONAL',
  }

  , CRUD: { [key: string]: string } = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
  }
  , UPDATE_REQUEST_TYPES: { [key: string]: { [key: string]: string } } = {
    HAS_UPDATE: {
      key: 'has_update',
      label: 'There is an update',
    },
    ATTORNEY_NO_LONGER_REPRESENTS_PLAINTIFF: {
      key: 'attorney_no_longer_represents_plaintiff',
      label: 'Attorney no longer represents this plaintiff',
    },
    ATTORNEY_NEVER_REPRESENTED_PLAINTIFF: {
      key: 'attorney_never_represented_plaintiff',
      label: 'Attorney has never represented this plaintiff',
    },
    NO_UPDATE: {
      key: 'no_update',
      label: 'No update since last time',
    },
  }
  , UPDATE_REQUEST_RADIO_OPTIONS: Array<{ [key: string]: string }> = Object.keys(UPDATE_REQUEST_TYPES).map(type => {
    const { key, label } = UPDATE_REQUEST_TYPES[type];
    return { key, label };
  })
  , TASKS_PAGE_SIZE = 10
  , DEDUPE_PAGE_SIZE = 10
  , CASE_UPDATE_TYPES: { [key: string]: { [key: string]: string } } = {
    add: {
      key: 'add',
      label: 'Add a case update',
    },
    request: {
      key: 'request',
      label: 'Request a case update',
    },
  }
  , CASE_UPDATE_RADIO_OPTIONS: Array<{ [key: string]: string }> = Object.keys(CASE_UPDATE_TYPES).map(type => {
    const { key, label } = CASE_UPDATE_TYPES[type];
    return { key, label };
  })
  , CONTACT_TYPES: { [key: string]: { [key: string]: string } } = {
    ATTORNEY: {
      baseUrl: '/attorneys/',
      display: 'Attorney',
      model: 'attorneys.attorney',
      route: 'attorney',
      shortDisplay: 'Attorney',
      type: 'ATTORNEY',
    },
    LAW_FIRM: {
      baseUrl: '/contacts/',
      display: 'Law Firm Contact',
      model: 'contacts.contact',
      route: 'law-firm-contact',
      shortDisplay: 'Law Firm Contact',
      type: 'LAW_FIRM',
    },
    OTHER: {
      baseUrl: '/contacts/',
      display: 'Other Contact',
      model: 'contacts.contact',
      route: 'other',
      shortDisplay: 'Other',
      type: 'OTHER',
    },
  }
  , CONTACT_URL_TYPE_MAP: { [key: string]: string } = {
    'attorney': 'ATTORNEY',
    'law-firm-contact': 'LAW_FIRM',
    'other': 'OTHER',
  }
  , MAX_CONTACT_RESULT_DISPLAY = 5
  , TRACKING_CONTACT_TYPES_BY_MODEL: {[key: string]: string} = {
    'attorneys.attorney': 'ATTORNEY',
    'contacts.contact': 'OTHER',
  }

  , JWT_COOKIE_KEY = '_mighty_auth'
  , TASK_TYPES = {
    case_update_request: 'tasks.casetrackingupdatetask',
    document_sharing: 'tasks.documentsharingtask',
  }
  , PAYOFF_ACTIONS: { [key: string]: string } = {
    download: 'download',
    email: 'email',
  }
  , SERVICING_TYPES: { [key: string]: string } = {
    ALL: 'ALL',
    NONE: 'NONE',
    SOME: 'SOME',
  }
  , CUSTOM_TYPES_TO_FIELDSETS: { [key: string]: { [key: string]: any }} = {
    BOOLEAN: { type: 'boolean', nullify: true },
    DATE: { type: 'date' },
    INTEGER: { type: 'number' },
    MONEY: { type: 'moneyNegative' },
    TEXT: { type: 'textDynamic' },
  }
  , LAW_FIRM_REGISTRY_ELIGIBILITY_STATUSES: { [key: string]: string } = {
    MISSING_DATA: 'MISSING_DATA',
    PENDING: 'PENDING',
    VERIFIED: 'VERIFIED',
    NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  }
  , ZENDESK_KNOWLEDGE_BASE_URL = 'https://mightyfundersoftware.zendesk.com/hc/en-us'
  , TERMS_OF_SERVICE_DEFAULT = {
    copy: 'Terms of Service',
    link: 'https://www.mighty.com/tos',
  }
;

/* tslint:enable object-literal-sort-keys */
export default {
  ACCEPTED_INPUT_TYPES,
  ACTIVITY_FILE_NAME_TRUNCATE_LENGTH,
  ACTIVITY_STATUS_CLASSNAMES,
  ACTIVITY_TRUNCATE_LENGTH,
  APP_UPDATE_CHECK_INTERVAL,
  APPLICATION_FACETS_API,
  APPLICATION_FACETS_DISPLAY,
  APPLICATION_MODELS,
  APPLICATION_STATUS,
  APPLICATION_TRANSITION_REASON_SHORT_CODES,
  APPLICATION_WORKFLOW_CONFIG,
  ASYNC_DOWNLOAD_STATE,
  BOOLEAN_FILTER_OPTIONS,
  BYTES_BASE,
  CASE_ASIDE_TABS,
  CASE_CLOSED_STATUSES,
  CASE_LABELS,
  CASE_MAIN_TABS,
  CASE_MEDICAL_STATUS,
  CASE_TRACKING_ATTRIBUTES,
  CASE_TRACKING_STATUS,
  CASE_UPDATE_RADIO_OPTIONS,
  CASE_UPDATE_TYPES,
  CENT,
  CENT_DECIMAL,
  CHART_COLORS,
  CONTACT_TYPES,
  CONTACT_URL_TYPE_MAP,
  CRUD,
  CUSTOM_TYPES_TO_FIELDSETS,
  DATE_FORMATS,
  DEBOUNCE_DELAY,
  DEDUPE_PAGE_SIZE,
  DEFAULT_TRUNCATION_LENGTH,
  DEFENDANT_NAME_LIMIT,
  DOCUMENT_CONVERT_CHECK_INTERVAL,
  EMPTY_FIELD,
  EXPORT_CHECK_INTERVAL,
  EXPORT_STATUS,
  EXPORT_TYPES,
  EXTERNAL_URL_PROPS,
  FUNDING_TYPE_LABELS,
  FUNDING_TYPES,
  GOOGLE_WEBFONTS_URL,
  GROUPS,
  HIJACK_QUERY_PARAMS,
  IMPORT_CHECK_INTERVAL,
  INTAKE_WIDGET_SUBMISSION_EVENT,
  INTEREST_RATE_DECIMAL_PLACES,
  JWT_COOKIE_KEY,
  KEYBOARD_ENTER_CODE,
  LAW_FIRM_REGISTRY_ELIGIBILITY_STATUSES,
  LAYOUT,
  LIEN_ACTIONS,
  LIEN_FEE_TYPE,
  LIEN_MODELS,
  LIEN_MODELS_BY_FUNDING_TYPE,
  LIEN_MODELS_BY_MODEL_NAME,
  LIEN_TYPE_LIMIT,
  LIEN_TYPES,
  LIEN_TYPES_LOOKUP,
  MAX_ATTACHMENTS_SIZE_MB,
  MAX_CONTACT_RESULT_DISPLAY,
  MIGHTY_SERVICE_TIERS,
  MODEL_TYPES,
  MONEY_DECIMAL_PLACES,
  MONTHS_PER_YEAR,
  NO_CHANGE_FIELD,
  NO_PERMISSION_TEXT,
  OPEN_APPLICATION_STATES,
  PAYOFF_ACTIONS,
  PERMISSIONS,
  PLAINTIFF_NAME_LIMIT,
  PRINCIPAL_PARTY_TYPES,
  REGISTRY_SHARING_ELIGIBILITY_OPTIONS,
  REGISTRY_SHARING_ELIGIBILITY_STATUSES,
  REPORT_EXPORT_CHECK_INTERVAL,
  REPORT_EXPORT_COOKIE_NAME,
  REPORT_EXPORT_COOKIE_TIMEOUT_INTERVAL,
  REPRESENTATIVE_ASSIGNMENTS,
  REPRESENTATIVES_UNASSIGNED,
  ROUTING,
  S3_STATIC_BUCKET_URL,
  SERVICING_TYPES,
  SUPPORT_EMAIL,
  TABLE_LONG_TEXT_TRUNCATION_LENGTH,
  TABLE_SUMMARY_ITEM_COUNT,
  TASK_TYPES,
  TASKS_PAGE_SIZE,
  TERMS_OF_SERVICE_DEFAULT,
  TOKEN,
  TOOLTIP_ID_TABLE,
  TRACKING_CONTACT_TYPES_BY_MODEL,
  TRANSITION_SLIDE,
  TRUNCATE_OBJECT_SEARCH,
  UPDATE_REQUEST_RADIO_OPTIONS,
  UPDATE_REQUEST_TYPES,
  USER_FLOW,
  WELL_COL_LABEL,
  WELL_COL_VALUE,
  ZENDESK_KNOWLEDGE_BASE_URL,
};
