import React, { Component } from 'react';
import { MenuItem } from 'react-bootstrap-typeahead';
import {
  IDisplaySearchResultItemConfiguration,
  getBaseSearchResultDisplayItem,
} from './SearchResultUtils';

class NamePhoneSearchResultItem extends Component<IDisplaySearchResultItemConfiguration> {

  public render () {
    const displayItem = getBaseSearchResultDisplayItem(this.props);
    return (
      <MenuItem key={displayItem.item.id} option={displayItem} index={displayItem.item.index}>
        <div className='search-result-item'>
          <p><strong>{displayItem.name}</strong></p>
          <p>{displayItem.phoneNumber}</p>
        </div>
      </MenuItem>
    );
  }
}

export default NamePhoneSearchResultItem;
