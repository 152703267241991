import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReactTable from 'react-table';
import autoBindMethods from 'class-autobind-decorator';
import { noop } from 'lodash';

import {
  Button,
} from 'react-bootstrap';

import EmptyStateButton from '../common/EmptyStateButton';
import FormattingUtils from '../../utils/FormattingUtils';
import TableRenderUtils from '../../utils/TableRenderingUtils';
import { Icon } from '../common';
import { IMedicalLienMedicalCode } from '../../interfaces';
import { insertIf, sumMoney } from '../../utils/util';
import { FunderStoreClass } from '../../stores';

const { renderMoney } = TableRenderUtils;
const { formatMoney } = FormattingUtils;

interface IProps {
  canAddMedicalCodes?: boolean;
  canDeleteMedicalCodes?: boolean;
  deleteMedicalCode: (code: IMedicalLienMedicalCode) => void;
  isEditing?: boolean;
  medicalCodes: IMedicalLienMedicalCode[];
  onOpen?: () => void;
}

interface IPropDefaults extends IProps {
  canAddMedicalCodes: boolean;
  canDeleteMedicalCodes: boolean;
  isEditing: boolean;
  onOpen: () => void;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
}

@inject('FunderStore')
@autoBindMethods
@observer
class MedicalCodesTable extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public static defaultProps: Partial<IProps> = {
    canAddMedicalCodes: true,
    canDeleteMedicalCodes: true,
    isEditing: false,
    onOpen: noop,
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  public render () {
    const {
        canAddMedicalCodes,
        canDeleteMedicalCodes,
        isEditing,
        medicalCodes,
        onOpen,
      } = this.propsWithDefaults
      , { FunderStore: { isMedicalProvider } } = this.injected
      , billingAmount = sumMoney(medicalCodes.map(code => code.billing_amount))
      , purchaseAmount = sumMoney(medicalCodes.map(code => code.purchase_amount))
      , purchaseAmountColumn = {
          accessor: 'purchase_amount',
          Cell: renderMoney,
          className: 'text-right',
          Footer: (_props: any) => <div>{formatMoney(purchaseAmount)}</div>,
          Header: 'Purchase AMT',
          headerClassName: 'text-right',
          maxWidth: 120,
          minWidth: 80,
          sortable: false,
      }
      , columns = [
        {
          accessor: 'medical_code.code',
          Footer: 'Total:',
          Header: 'Code',
          sortable: false,
          width: 70,
        },
        {
          accessor: 'medical_code.description',
          Cell: (row: any) => {
            return <div className='ellipsis'>{row.value}</div>;
          },
          Header: 'Description',
          sortable: false,
        },
        {
          accessor: 'billing_amount',
          Cell: renderMoney,
          className: 'text-right',
          Footer: (_props: any) => <div>{formatMoney(billingAmount)}</div>,
          Header: 'Billing AMT',
          headerClassName: 'text-right',
          maxWidth: 120,
          minWidth: 80,
          sortable: false,
        },
        ...insertIf(!isMedicalProvider, purchaseAmountColumn),
        {
          accessor: 'blank',
          Cell: (row: any) => {
            return canDeleteMedicalCodes
              ? (
                <Button
                  bsStyle='link'
                  className='no-padding'
                  onClick={this.props.deleteMedicalCode.bind(this, row.original)}
                >
                  <Icon type='trash' />
                </Button>
              )
              : null;
          },
          className: 'buttons',
          Header: '',
          headerClassName: 'buttons',
          maxWidth: 42,
          minWidth: 42,
          sortable: false,
        },
      ];

    return (
      <div>
        {!isEditing &&
          <h3>
            Medical Codes and Descriptions
            {canAddMedicalCodes && !!medicalCodes.length &&
              <Button onClick={onOpen} bsStyle='link' className='no-padding pull-right'>
                <Icon type='pencil' />
              </Button>
            }
          </h3>
        }

        {
          !medicalCodes.length && !isEditing
            ? <EmptyStateButton
              classNames='add-medical-codes content'
              emptyText='Add Medical Codes'
              onClick={onOpen}
            />
            : <ReactTable
              className='-total'
              columns={columns}
              data={medicalCodes}
              minRows={0}
              noDataText='No Medical Codes'
              pageSize={medicalCodes.length}
              resizable={false}
              showPagination={false}
            />
        }
      </div>
    );
  }
}

export default MedicalCodesTable;
