import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { FormattingUtils } from '../../utils';
import { ICase } from '../../interfaces';
import CaseLabel from './CaseLabel';
import { FunderStoreClass } from '../../stores';

const { getNameOrDefault } = FormattingUtils;

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  FunderStore: FunderStoreClass;
}

@inject('FunderStore')
@observer
class CaseHeaderTitle extends Component <IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { _case } = this.props
      , { FunderStore } = this.injected
      ;

    return (
      <div className='col-title'>
        <h1 className='fs-mask'>
          {getNameOrDefault(_case.plaintiff)}
          {_case.hasLiens({is_imported: true}) && <span className='tag-imported'>Imported</span>}
        </h1>
        {FunderStore.canSeeVanityIds && <div className='id'>Case ID <span>{_case.vanity_id}</span></div>}
        <div className='list-tracking-status'>
          {_case.return_status && <CaseLabel status={_case.return_status} />}
        </div>
      </div>
    );
  }
}

export default CaseHeaderTitle;
