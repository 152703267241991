// tslint:disable:max-classes-per-file
import { addMilliseconds } from 'date-fns';
import autoBindMethods from 'class-autobind-decorator';
import { every, pick } from 'lodash';

import Client from '../clients/Client';
import AppConstants from '../constants/AppConstants';
import { ITransport } from '../interfaces';

import SmartBool from './SmartBool';
import Storage from './Storage';
import toast from './toast';

const {
  REPORT_EXPORT_CHECK_INTERVAL,
  REPORT_EXPORT_COOKIE_TIMEOUT_INTERVAL,
  REPORT_EXPORT_COOKIE_NAME,
  ASYNC_DOWNLOAD_STATE,
} = AppConstants;

type IOnSuccess = (arg: any) => any;
interface IParams { [key: string]: string; }
interface IExportHandlerProps {
  onSuccess?: IOnSuccess;
  canExportReports: SmartBool;
}
interface IExportProps {
  pollingEndpoint: string;
  params: IParams;
  createEndpoint?: string;
}
interface ICookieProps {
  id: string;
  pollingEndpoint: string;
  params: IParams;
}

@autoBindMethods
class AsyncReportExportHandler {
  private poll?: any;
  private toastLoaderId?: string | number;
  private transport: ITransport;
  private canExportReports: SmartBool;
  private get storedExport () { return Storage.getCookie(REPORT_EXPORT_COOKIE_NAME); }
  private pickCookieFields (selection: string | string[]) { return pick(this.storedExport, selection) as ICookieProps; }

  constructor (transport: ITransport, { onSuccess, canExportReports }: IExportHandlerProps) {
    this.transport = transport;
    this.canExportReports = canExportReports;
    this.getExporterFromLastSession(onSuccess);
  }

  private getExporterFromLastSession (onSuccess?: IOnSuccess) {
    const cookieFields = this.pickCookieFields(['id', 'pollingEndpoint', 'params']);
    if (every(cookieFields) && onSuccess) {
      const exporter = new AsyncReportExport(this.transport, cookieFields);
      this.createPollingCheck(exporter, this.storedExport.id, onSuccess);
    }
    else {
      this.resetExportEnvironment();
    }
  }

  public async startAsyncProcess (createEndpoint: string, pollingEndpoint: string, params: IParams, onSuccess: IOnSuccess) {
    try {
      this.canExportReports.setFalse();
      const exporter = new AsyncReportExport(this.transport, { pollingEndpoint, params, createEndpoint })
        , data = await exporter.startAsyncTask();

      this.createPollingCheck(exporter, data.id, onSuccess);
    }
    catch (e) {
      toast.error('Export failed! It seems you have another report export in progress');
      this.resetExportEnvironment(false);
    }
  }

  private createPollingCheck (exporter: AsyncReportExport, id: string, onSuccess: IOnSuccess) {
    this.canExportReports.setFalse();
    this.toastLoaderId = toast.warn(
      'Your report is being prepared. For large exports, this may take a while.',
      { autoClose: false, draggable: true },
    );
    this.poll = setInterval(() => {
      exporter.checkExportStatus(id, onSuccess, this.resetExportEnvironment);
    }, REPORT_EXPORT_CHECK_INTERVAL);
  }

  private resetExportEnvironment (canExport = true) {
    Storage.removeCookie(REPORT_EXPORT_COOKIE_NAME);
    this.canExportReports.set(canExport);
    if (this.toastLoaderId) { toast.dismiss(this.toastLoaderId); }
    // Don't test this because sleeping for this long exceeds jest's async timeout limit
    // istanbul ignore next
    if (this.poll) {
      clearInterval(this.poll);
      this.poll = null;
    }
  }
}

@autoBindMethods
class AsyncReportExport {
  private transport: ITransport;
  private createClient: Client | null;
  private pollingClient: Client;
  private params: IParams = {};

  constructor (transport: ITransport, { createEndpoint, pollingEndpoint, params }: IExportProps) {
    this.transport = transport;
    this.createClient = createEndpoint ? new Client(createEndpoint, this.transport) : null;
    this.pollingClient = new Client(pollingEndpoint, this.transport);
    this.params = params;
  }

  public async startAsyncTask () {
    const data = await (this.createClient ? this.createClient.createAction('export', this.params) : {})
      , cookieData = { ...data, pollingEndpoint: this.pollingClient.endpoint, params: this.params }
      , cookieOptions = { expires: addMilliseconds(new Date(), REPORT_EXPORT_COOKIE_TIMEOUT_INTERVAL), secure: true }
      ;

    Storage.setCookie(REPORT_EXPORT_COOKIE_NAME, cookieData, cookieOptions);
    return data;
  }

  public async checkExportStatus (id: string, onSuccess: IOnSuccess, clearParentState: () => void) {
    const pollData = await this.pollingClient.retrieve(id);
    if ([ASYNC_DOWNLOAD_STATE.NEW, ASYNC_DOWNLOAD_STATE.IN_PROGRESS].includes(pollData.status)) {
      return;
    }
    else if (pollData.status === ASYNC_DOWNLOAD_STATE.COMPLETE) {
      toast.success('Success! You can now download your exported report');
      clearParentState();
      return await onSuccess(pollData);
    }
    else if (pollData.status === ASYNC_DOWNLOAD_STATE.FAILED) {
      toast.error('Export Failed! If you think this is a mistake, please contact Mighty support');
    }
    else {
      toast.error('Something went wrong! Please refresh your browser and try again.');
    }
    clearParentState();
  }
}

export default AsyncReportExportHandler;
