import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { action, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { chunk, get, isEmpty } from 'lodash';

import {
  ButtonToolbar,
  Modal,
  Table,
  Well,
} from 'react-bootstrap';

import { AppConstants } from '../../../constants';

import { FormattingUtils } from '../../../utils';

import {
  ConfirmDelete,
  DownloadButton,
  InfoWell,
  PermissionRequiredToolTip,
} from '../../common';

import {
  Card,
  CardRow,
  EditableCard,
} from '../../../lib/mighty-fields';

import ReturnDetailsWell from '../ReturnDetailsWell';
import TermsTable from '../TermsTable';
import { GuardedCapitalFundingsList } from '../main-details/capital-fundings/CapitalFundingsList';
import { GuardedReviseContractButton } from '../main-applications/ApplicationTransitionButtons';
import {
  FunderStoreClass,
  SessionStoreClass,
  UiStoreClass,
  CaseStoreClass,
} from '../../../stores';

import GuardedDeleteLienButton from '../DeleteLienButton';
import LienDocuments from './LienDocuments';
import { LienModel } from '../../../models';
import { ILienReturn } from '../../../interfaces';
import { getCustomFieldset, joinCustomFields } from '../../../utils/util';
import LienInfoEditableCard from '../LienInfoEditableCard';

const {
  LIEN_ACTIONS,
  LIEN_FEE_TYPE,
  LIEN_TYPES,
  LIEN_TYPES_LOOKUP,
  LIEN_MODELS_BY_MODEL_NAME,
} = AppConstants;

const {
  formatDate,
  formatMoney,
} = FormattingUtils;

interface IProps {
  lien: LienModel;
  onClose: () => void;
  onLienClick: (action: string, id: string, application: string) => void;
}

interface IInjected extends IProps {
  ApplicationStore: any;
  CaseStore: CaseStoreClass;
  FunderStore: FunderStoreClass;
  LienStore: any;
  PriorFundingStore: any;
  SessionStore: SessionStoreClass;
  UiStore: UiStoreClass;
}

@inject(
  'ApplicationStore',
  'CaseStore',
  'FunderStore',
  'LienStore',
  'PriorFundingStore',
  'SessionStore',
  'UiStore',
)
@autoBindMethods
@observer
class FundingDetailsModal extends Component<IProps> {
  @observable private showDeleteLienConfirmation = false;
  @observable private application: any = null;
  @observable private lien: LienModel | null = null;
  @observable private loading = false;
  @observable private priorFunding = null;

  constructor (props: IProps) {
    super(props);
    this.lien = props.lien;
  }

  private get injected () {
    return this.props as IInjected;
  }

  @action
  private async loadData (ApplicationStore: any, LienStore: any, PriorFundingStore: any, lien: LienModel) {
    if (!lien) { return; }
    this.loading = true;

    const isLegacy = lien.type === LIEN_TYPES.legacy
      , type = LIEN_TYPES_LOOKUP[lien.type];

    if (type) {
      this.lien = await LienStore.retrieve(lien.id, type, { includeDocuments: true, legacy: isLegacy });
    }

    if (this.lien && this.lien.application) {
      this.application = ApplicationStore.applications.get(this.lien.application);
    }

    if (lien.prior_funding) {
      this.priorFunding = await PriorFundingStore.retrieve(lien.prior_funding);
    }

    this.loading = false;
  }

  public componentDidMount () {
    this.componentWillReceiveProps(this.injected);
  }

  public componentWillReceiveProps (nextProps: IInjected) {
    const { ApplicationStore, LienStore, lien, PriorFundingStore } = nextProps;
    this.loadData(ApplicationStore, LienStore, PriorFundingStore, lien);
  }

  private handleContractDownloadClick () {
    const { LienStore, lien } = this.injected;
    LienStore.downloadContract(lien);
  }

  private renderLienDocuments () {
    if (!this.lien || !this.lien.documents) {
      return null;
    }

    return (
      <LienDocuments
        documents={toJS(get(this.lien, 'documents'))}
        lien={this.lien}
      />
    );
  }

  private renderReturnDetails () {
    if (!this.lien || !this.lien.lien_returns) { return; }
    return (this.lien.lien_returns.map((lienReturn: ILienReturn) => (
      <ReturnDetailsWell
        className='space-between'
        key={lienReturn.id}
        lien={this.lien}
        lien_return={lienReturn}
      />
    )));
  }

  private renderLegacyContractDetails () {
    // istanbul ignore next
    if (!this.lien) {
      return null;
    }

    /* eslint-disable sort-keys */
    return (
      <Card
        className='space-between'
        fieldSets={[[
          { field: 'funding_amount', type: 'money', label: 'Amount to Plaintiff' },
          { field: 'principal', type: 'money', label: 'Total Principal' },
          { field: 'rate_of_return', type: 'percentage' },
          { field: 'date_of_agreement', type: 'date', label: 'Agreement Date' },
          { field: 'funding_schedule_description', label: 'Schedule Description' },
        ]]}
        model={this.lien}
        title='Contract Details'
      />
    );
    /* eslint-enable sort-keys */
  }

  private renderLegacySchedule () {
    if (!this.lien || isEmpty(toJS(this.lien.schedule_items))) {
      return;
    }

    return (
      <Well className='repayment-schedule'>
        <h3>Repayment Schedule</h3>
        <Table condensed>
          <thead>
            <tr>
              <th>End Date</th>
              <th>Amount Due</th>
            </tr>
          </thead>
          <tbody>
            {this.lien.schedule_items.map((scheduleItem: any, idx: number) => (
              <tr key={idx}>
                <td>{formatDate(scheduleItem.end_date)}</td>
                <td>{formatMoney(scheduleItem.amount_due)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Well>
    );
  }

  private renderTermsTable () {
    if (!this.lien || isEmpty(this.lien.terms)) {
      return null;
    }

    return (
      <Well>
        <TermsTable lien={this.lien} allLocked />
      </Well>
    );
  }

  private onReviseClick () {
    const { onClose, lien, onLienClick } = this.props
      , { application, id } = lien;
    onLienClick(LIEN_ACTIONS.revise, id, application);
    onClose();
  }

  @action
  private async updateCustomFields (model: any = {}) {
    if (!this.lien) { return; }
    const { LienStore } = this.injected
      , lienData = this.lien.serialize()
      , customFieldData = {...lienData.custom_field_data, ...model.custom_field_data}
      , data = {...lienData, custom_field_data: customFieldData}
      , typeKey = LIEN_MODELS_BY_MODEL_NAME[this.lien.type].key
      ;

    await LienStore.upsert(data, typeKey);
  }

  private renderReviseButton () {
    return (
      <span>
        <GuardedReviseContractButton
          key='edit-contract'
          className='pull-right edit-contract'
          onClick={this.onReviseClick}
        />
        <PermissionRequiredToolTip />
      </span>
    );
  }

  // tslint:disable-next-line cyclomatic-complexity
  private renderContractDetails () {
    const { lien } = this.props
      , isLegacy = lien.type === LIEN_TYPES.legacy;

    return (
      <Well>
        <h3>
          Contract Details
          {!isLegacy && this.renderReviseButton()}
          <DownloadButton className='pull-right download-contract' onClick={this.handleContractDownloadClick}>
            Download Contract
          </DownloadButton>
        </h3>

        <InfoWell className='space-between'>
          <CardRow
            fieldConfig={{ field: 'funding_amount', label: 'Amount to Plaintiff', type: 'money' }}
            model={this.lien || {}}
          />
          <CardRow
            fieldConfig={{ field: 'buyout_amount', type: 'money' }}
            model={this.lien || {}}
          />

          {this.lien && this.lien.fees && this.lien.fees.map((fee: any, idx: number) => (
            <CardRow
              fieldConfig={{ field: 'fee_amount', label: LIEN_FEE_TYPE[fee.fee_type], type: 'money' }}
              key={idx}
              model={fee}
            />
          ))}

          <CardRow
            fieldConfig={{ field: 'interest_start_date', type: 'date' }}
            model={this.lien || {}}
          />
          <CardRow
            fieldConfig={{ field: 'agreement_date', type: 'date' }}
            model={this.lien || {}}
          />

          {this.lien && this.lien.funded_date &&
            <CardRow
              fieldConfig={{ field: 'funded_date', type: 'date' }}
              model={this.lien || {}}
            />}
        </InfoWell>
      </Well>
    );
  }

  private renderCapitalProviders () {
    if (!this.lien) { return; }
    const { FunderStore } = this.injected,
      { capital_fundings, id } = this.lien;

    if (!FunderStore.canSeeCapitalProviders) {
      return;
    }

    return (
      <GuardedCapitalFundingsList
        capitalFundings={toJS(capital_fundings)}
        lienId={id}
      />
    );
  }

  private renderApplicationInfo () {
    if (!this.application) {
      return null;
    }

    return (
      <Card
        className='space-between'
        fieldSets={[[
          { field: 'created_at', label: 'Application Created', type: 'date' },
          { field: 'requested_amount' , type: 'money' },
        ]]}
        model={this.application || {}}
        title='Application Info'
      />
    );
  }

  private renderPriorFundingInfo () {
    if (!this.priorFunding) {
      return null;
    }

    return (
      <Card
        className='space-between'
        fieldSets={[[
          { field: 'funder_name' },
          { field: 'buyout_amount', type: 'money' },
          { field: 'expiration_date', type: 'date' },
        ]]}
        model={this.priorFunding || {}}
        title='Prior Funding Info'
      />
    );
  }

  private renderCustomFieldData () {
    const funderFields = get(this.injected.FunderStore, 'funder.custom_lien_fields', [])
      , funderFieldTypes = get(this.injected.FunderStore, 'funder.custom_lien_field_types')
      , dataFields = Object.keys(get(this.lien, 'custom_field_data', []))
      , customFields = joinCustomFields(funderFields, dataFields)
      // tslint:disable-next-line no-magic-numbers
      , fieldSets = chunk(customFields.map(field => getCustomFieldset(funderFieldTypes, field)), 50)
      ;

    if (!customFields.length) {
      return null;
    }

    return fieldSets.map((fieldSet: any, idx: number) => (
      <EditableCard
        key={`custom-field-sets-${idx}`}
        fieldSets={[fieldSet]}
        model={get(this.lien, 'custom_field_data', {})}
        onSave={this.updateCustomFields}
        title='Additional Lien Information'
        wrapperName='custom_field_data'
      />
    ));
  }

  @action
  private async handleDeleteLienClick () {
    if (!this.lien) { return; }
    const { LienStore, SessionStore, UiStore } = this.injected
      , eventData = { lien: this.lien.serialize(), priorFunding: this.priorFunding };

    UiStore.modals.FundingDetails.close();
    await Promise.all([
      LienStore.delete({ caseId: this.lien.case, lienId: this.lien.id }),
      SessionStore.trackEvent('LIEN_DELETED_EVENT', eventData),
    ]);
  }

  private toggleShowDeleteLienConfirmation () {
    this.showDeleteLienConfirmation = !this.showDeleteLienConfirmation;
  }

  private renderLienDeleteButton () {
    return (
      <ButtonToolbar className='lien-delete'>
        <GuardedDeleteLienButton onClick={this.toggleShowDeleteLienConfirmation} />
      </ButtonToolbar>
    );
  }

  public render () {
    const { onClose } = this.props
      , confirmDeleteMessage = (
        <div>
          <p>Are you sure you want to delete this lien and application?</p>
          <p><b>Warning: this cannot be undone.</b></p>
        </div>
      );

    return (
      <Modal show className='right side-modal modal-funding-details' onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lien Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.loading ?
            <div className='loading'>Loading...</div>
            :
            <div>
              <LienInfoEditableCard lien={this.lien} priorFunding={this.priorFunding} />
              {this.renderLienDocuments()}
              {this.renderTermsTable()}
              {this.renderReturnDetails()}
              {this.lien && this.lien.type === LIEN_TYPES.legacy
                ? this.renderLegacyContractDetails()
                : this.renderContractDetails()}
              {this.renderCapitalProviders()}
              {this.renderCustomFieldData()}
              {this.renderApplicationInfo()}
              {this.renderPriorFundingInfo()}
              {this.lien && this.lien.type === LIEN_TYPES.legacy
                && this.renderLegacySchedule()}
              {this.renderLienDeleteButton()}
              <ConfirmDelete
                fixed
                message={confirmDeleteMessage}
                showConfirmDelete={this.showDeleteLienConfirmation}
                onClickDelete={this.handleDeleteLienClick}
                onClickCancel={this.toggleShowDeleteLienConfirmation}
              />
            </div>
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default FundingDetailsModal;
