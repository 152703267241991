import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { MenuItem } from 'react-bootstrap-typeahead';
import {
  IDisplaySearchResultItemConfiguration,
  getBaseSearchResultDisplayItem,
  ISearchResultItemProps,
} from './SearchResultUtils';

import FormattingUtils from '../../../../utils/FormattingUtils';

const { pluralize } = FormattingUtils;

@autoBindMethods
@observer
class AttorneySearchResultItem extends Component<ISearchResultItemProps> {

  public render () {
    const displayItemConfiguration: IDisplaySearchResultItemConfiguration = {
        ...this.props,
        entity: 'attorney',
        path: '/contacts/people/',
      }
      , displayItem = getBaseSearchResultDisplayItem(displayItemConfiguration);

    return (
      <MenuItem key={displayItem.item.id} option={displayItem} position={this.props.index}>
        <div className='search-result-item'>
          <p><strong>{displayItem.fullName}</strong></p>
          <p>{displayItem.phoneNumber}, {displayItem.email}
            <span className='pull-right'>{pluralize('case', displayItem.item.cases_count, true)}</span>
          </p>
        </div>
      </MenuItem>
    );
  }
}

export default AttorneySearchResultItem;
