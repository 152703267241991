import autoBindMethods from 'class-autobind-decorator';
import { chunk, find, findIndex, flatten, get } from 'lodash';
import { observable, toJS } from 'mobx';
import { browserHistory } from 'react-router';

import AppConstants from '../../../constants/AppConstants';
import ClientsClass from '../../../clients/ClientsClass';
import SmartBool from '../../../utils/SmartBool';
import { ICount, IDedupableObject, IDedupeMatch, ISelections } from '../../../interfaces/dedupeInterfaces';
import { toast } from '../../../utils';
import { toggleArrayIncludes } from '../../../utils/util';

import { DEDUPE_MODELS_DATA_DISPLAY } from './dedupeModelsDataDisplay';

const {
  DEDUPE_PAGE_SIZE,
  MODEL_TYPES,
} = AppConstants;

@autoBindMethods
class DedupePageState {
  private clients: ClientsClass;

  private _isLoading = new SmartBool(true);
  private _isFetching = new SmartBool(true);
  private model: string;

  @observable private counts: ICount[] = [];
  @observable private dedupableObjects = new Map();
  @observable private _dedupeMatches: IDedupeMatch[] = [];
  @observable private selections = new Map();
  @observable public filters: { [key: string]: any } = {};

  @observable private dismissedIds: string[] = [];
  @observable private mergedIds: string[] = [];
  @observable private next: null | string = null;

  constructor (clients: ClientsClass, model: string) {
    this.filters = browserHistory.getCurrentLocation().query;
    this.clients = clients;
    this.model = model;
    this.fetch();
  }

  public updateModel (model: string) {
    // istanbul ignore next
    if (this.model !== model) {
      this.model = model;
      this.fetch();
    }
  }

  private get client () {
    return this.clients.dedupe;
  }

  private get modelClient () {
    return {
      [MODEL_TYPES.attorney.key]: this.clients.attorneys,
      [MODEL_TYPES.capitalProvider.key]: this.clients.capitalProviders,
      [MODEL_TYPES.case.key]: this.clients.cases,
      [MODEL_TYPES.medicalFacility.key]: this.clients.medicalFacilities,
      [MODEL_TYPES.medicalProvider.key]: this.clients.medicalProviders,
      [MODEL_TYPES.partyOwnedLawFirm.key]: this.clients.lawFirms,
    }[this.model];
  }

  private reset () {
    this._dedupeMatches = [];
    this.dedupableObjects.clear();
    this.selections.clear();

    this.dismissedIds = [];
    this.mergedIds = [];
    this.next = null;
  }

  private async refresh () {
    await this._isLoading.until(Promise.all([this.getCounts(), this.list()]));
  }

  private async fetch () {
    this.reset();
    await this._isFetching.until(this.refresh());
  }

  private async getCounts () {
    const response = await this.client.counts();
    this.counts = response || [];
  }

  private async fetchObjectsByIds (ids: string[]) {
    // split into chunks manually to prevent request being too large
    const batchedIds = chunk(ids, DEDUPE_PAGE_SIZE)
      , objects = await Promise.all(
        // use listAll in case endpoint's page size is less than PAGE_SIZE
        batchedIds.map((batch: string[]) => this.modelClient.listAll({ params: { id: batch }}))
    );

    return flatten(objects);
  }

  private async fetchDedupableObjects (dedupeMatches: IDedupeMatch[]) {
    // Takes all un-fetched Object IDs and fetches the associated objects
    if (!dedupeMatches.length) { return; }

    const matchIds = dedupeMatches
        .map(dedupeMatch => dedupeMatch.matched_ids) // => Array of ID Arrays
        .reduce((accumulator, currentValue) => accumulator.concat(toJS(currentValue))) // => Array of IDs
        .filter(matchId => !this.dedupableObjects.has(matchId)) // Filter out fetched IDs
      , responseObjs = await this.fetchObjectsByIds(matchIds); // Fetch un-fetched IDs

    responseObjs.forEach((obj: { id: string }) => this.dedupableObjects.set(obj.id, obj)); // Store each fetched object
  }

  private async awaitRequest (request: Promise<any>) {
    const responseMatches = await request
      , dedupeMatches = get(responseMatches, 'results', [])
      , next = get(responseMatches, 'next', null);

    await this.fetchDedupableObjects(dedupeMatches);

    this._dedupeMatches = this._dedupeMatches.concat(dedupeMatches);
    this.next = next;
  }

  public clearFilters () {
    this.filters = {};
    this.fetch();
  }

  private async list () {
    const params = { model: this.model, status: 'NEW', ...this.filters }
      , request = this.client.list({ params });

    this.dismissedIds = [];
    this.mergedIds = [];

    await this.awaitRequest(request);
  }

  // Public API begins below

  public get isLoadingInitial () {
    return this._isFetching.isTrue;
  }

  public get isLoading () {
    return this._isLoading.isTrue;
  }

  public get dedupeMatches () {
    return this._dedupeMatches;
  }

  public get count () {
    return this.getModelCount(this.model);
  }

  public get modelDisplay () {
    return DEDUPE_MODELS_DATA_DISPLAY[this.model] || DEDUPE_MODELS_DATA_DISPLAY['default'];
  }

  public get showLoadMore () {
    return !!this.next;
  }

  public getModelCount (model: string) {
    const countObj = find(this.counts, { model });
    return get(countObj, 'count', 0);
  }

  public getSelections (dedupeMatch: IDedupeMatch): ISelections {
    if (this.selections.has(dedupeMatch.id)) {
      return this.selections.get(dedupeMatch.id);
    }

    return {
      canonical_id: dedupeMatch.matched_ids[0],
      merge_ids: [],
    };
  }

  public getMergingCount (dedupeMatch: IDedupeMatch) {
    const selections = this.getSelections(dedupeMatch)
      , checkedCount = selections.merge_ids.length
      , isCanonicalChecked = selections.merge_ids.includes(selections.canonical_id)
      , mergingCount = isCanonicalChecked ? checkedCount : checkedCount + 1;

    return mergingCount;
  }

  public isMatchMerged (dedupeMatch: IDedupeMatch) {
    return this.mergedIds.includes(dedupeMatch.id);
  }

  public isMatchDismissed (dedupeMatch: IDedupeMatch) {
    return this.dismissedIds.includes(dedupeMatch.id);
  }

  public getMatchObjects (dedupeMatch: IDedupeMatch): IDedupableObject[] {
    return dedupeMatch.matched_ids
      .map((matchId: string) => this.dedupableObjects.get(matchId))
      .filter(d => !!d);
  }

  public async loadMore () {
    if (!this.next) { return; }
    const request = this.client.loadMore(this.next);

    await this.awaitRequest(request);
  }

  public async dismiss (dedupeMatch: IDedupeMatch) {
    await this._isLoading.until((async () => {
      await this.client.dismiss(dedupeMatch.id);
      this.dismissedIds.push(dedupeMatch.id);
      await this.getCounts();
      toast('Dismissed match');
      this.selections.delete(dedupeMatch.id);
    })());
  }

  public async merge (dedupeMatch: IDedupeMatch) {
    await this._isLoading.until((async () => {
      const selections = this.getSelections(dedupeMatch)
        , mergingCount = this.getMergingCount(dedupeMatch);
      const response = await this.client.putAction(dedupeMatch.id, 'merge', selections);
      if (response) {
        const idx = findIndex(this._dedupeMatches, { id: dedupeMatch.id });
        this._dedupeMatches[idx] = response;
      }
      else {
        this.mergedIds.push(dedupeMatch.id);
        await this.getCounts();
      }
      toast.success(`Successfully merged ${mergingCount} records!`);
      this.selections.delete(dedupeMatch.id);
    })());
  }

  public async handleCanonicalChange (dedupeMatch: IDedupeMatch, dedupableObject: IDedupableObject) {
    const selections = this.getSelections(dedupeMatch);
    selections.canonical_id = dedupableObject.id;
    this.selections.set(dedupeMatch.id, selections);
  }

  public async handleMergeChange (dedupeMatch: IDedupeMatch, dedupableObject: IDedupableObject) {
    const selections = this.getSelections(dedupeMatch);
    selections.merge_ids = toggleArrayIncludes(selections.merge_ids, dedupableObject.id);
    this.selections.set(dedupeMatch.id, selections);
  }
}

export default DedupePageState;
