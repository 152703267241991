import React, { Component } from 'react';
import URI from 'urijs';
import autoBindMethods from 'class-autobind-decorator';
import { browserHistory } from 'react-router';
import { get, sortBy } from 'lodash';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

import {
  Alert,
  Col,
  Button,
  HelpBlock,
  Row,
  Well,
} from 'react-bootstrap';

import ClientsClass from '../../clients/ClientsClass';
import ICase from '../../interfaces/models/ICase';
import CaseReferenceSidebar from '../common-collaboration/CaseReferenceSidebar';
import { Form, MultiSelectTypeahead } from '../common-formsy';
import { FormattingUtils, SmartBool, TableRenderingUtils, toast} from '../../utils';
import { Loader, DocumentTableBody, Page, Spacer } from '../common';
import { SessionStoreClass } from '../../stores';
import { getContactsFromCase } from '../../utils/util';

const { formatContactNameAndEmail, getNameOrDefault, toKey } = FormattingUtils;
const { renderFilenameLink, renderFileType, renderUnformatted } = TableRenderingUtils;

interface IInjected {
  Clients: ClientsClass;
  SessionStore: SessionStoreClass;
}

@inject('Clients', 'SessionStore')
@autoBindMethods
@observer
class DocumentSharingPage extends Component {
  @observable private checkedDocumentIds: string[] = [];
  @observable private isLoading = new SmartBool();
  @observable private showDocumentError = false;
  private _case: ICase | null = null;
  private documents: [object] | null = null;

  public componentDidMount () {
    const location = browserHistory.getCurrentLocation()
      , caseId = URI(location.pathname).segment(1)
      , checkedDocumentIds = get(location, 'state.checkedDocumentIds')
      ;

    if (checkedDocumentIds) {
      this.checkedDocumentIds = checkedDocumentIds;
    }

    if (caseId) {
      this.fetchCaseInformation(caseId);
    }
  }

  private get injected () {
    return this.props as IInjected;
  }

  private getViewUrl (document: { download_url: string }) {
    const { download_url } = document
      , url = `${download_url}${toKey({ dl: 0 }, download_url)}`;

    return this.injected.SessionStore.addAuthenticationToUrl(url);
  }

  private get columns () {
    return [
      {
        accessor: 'file',
        Cell: renderFileType,
        className: 'col-file',
        Header: null,
        maxWidth: 40,
      },
      {
        accessor: 'name',
        Cell: renderFilenameLink,
        getViewUrl: this.getViewUrl,
        Header: 'Name',
        minWidth: 125,
        sortable: true,
      },
      {
        accessor: 'type.name',
        Cell: renderUnformatted,
        Header: 'type',
        minWidth: 125,
        sortable: true,
      },
    ];
  }

  private async fetchCaseInformation (caseId: string) {
    const { Clients } = this.injected;

    this.isLoading.setTrue();
    [this._case, this.documents] = await Promise.all([
      Clients.cases.retrieve(caseId),
      Clients.caseDocuments.list({params: { case: caseId } }),
    ]);
    this.isLoading.setFalse();
  }

  public setCheckedDocumentIds (checkedDocumentIds: string[]) {
    if (this.checkedDocumentIds && this.showDocumentError) {
      this.showDocumentError = false;
    }
    this.checkedDocumentIds = checkedDocumentIds;
    browserHistory.replace({ pathname: browserHistory.getCurrentLocation().pathname, state: {checkedDocumentIds: this.checkedDocumentIds} });
  }

  private async handleValidSubmit (model: any, _resetForm: () => void, _invalidateForm: () => void) {
    const { Clients } = this.injected;

    // istanbul ignore next
    if (!this._case) {
      return;
    }

    if (!this.checkedDocumentIds.length) {
      this.showDocumentError = true;
      return;
    }

    const caseId = this._case.id
      , bulkData = model.contacts
      .map((contact: any) =>
        ({
          case: caseId,
          documents_shared: {
            case: caseId,
            document_ids: toJS(this.checkedDocumentIds),
            person_shared_with_id: contact.id,
          },
          implicit_request: true,
          requester: contact.id,
        }));

    await Clients.documentSharingTask.create(bulkData);

    browserHistory.push(`/case/${caseId}/documents`);
    toast.success('Success! Your documents have been shared.');
  }

  private get caseContactOptions () {
    // istanbul ignore next
    if (!this._case) {
      return [];
    }

    const doesContactHaveEmail = (contact: any) => !contact.email
      , caseContacts = getContactsFromCase(this._case)
      , contactOptions = sortBy(caseContacts, [doesContactHaveEmail, 'first_name']);

    return contactOptions
      .map(contact => ({
        ...contact,
        contactDisplay: formatContactNameAndEmail(contact),
        disabled: !contact.email,
      }));
  }

  public render () {
    if (this.isLoading.isTrue) {
      return <Loader className='page-loader' logo />;
    }

    if (!this._case) {
      return null;
    }

    return (
      <Page name='document-sharing' type='detail' hasSidebar>
        <CaseReferenceSidebar page={`/case/${this._case.id}/documents/feed`} data={this._case} isCaseObject />
        <Page.Content>
          <header className='condensed'>
            <h1 className='fs-mask'>Share Documents for {getNameOrDefault(this._case.plaintiff)}</h1>
          </header>
          <div className='main'>
            <Row>
              <Col className='col-main' xs={12}>
              <Row>
                <Col xs={10} sm={8} lg={6}>
                <Form className='form-page' onValidSubmit={this.handleValidSubmit}>
                  <Well>
                    <h3>Selected Files</h3>
                    <DocumentTableBody
                      columns={this.columns}
                      documents={this.documents}
                      hasHeader
                      onCheck={this.setCheckedDocumentIds}
                      selectable
                      selectedDocumentIds={this.checkedDocumentIds}
                    />
                  </Well>

                  {this.showDocumentError &&
                    <Alert bsStyle='danger'><p>At least one document must be selected.</p></Alert>
                  }

                  <Spacer large />

                  <h5>Who would you like to share these documents with?</h5>

                  <div className='modal-form-style'>
                    <MultiSelectTypeahead
                      field={'contacts'}
                      label='Contact names'
                      labelKey={'contactDisplay'}
                      name={'contacts'}
                      options={this.caseContactOptions}
                      required
                      value={''}
                    />
                    <HelpBlock>Only contacts with email addresses can be selected.</HelpBlock>
                  </div>

                  <hr />

                  <div className='footer'>
                    <Button
                      bsStyle='primary'
                      disabled={this.showDocumentError}
                      type='submit'
                    >
                      Send Documents
                    </Button>
                  </div>
                </Form>
                </Col>
              </Row>
              </Col>
            </Row>
          </div>

        </Page.Content>
      </Page>
    );
  }
}

export default DocumentSharingPage;
