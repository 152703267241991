import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { formatDateTime, formatDate } from '@mighty-justice/utils';

import { LinkButton, Icon } from '../common';

interface IProps {
    activity: any;
    isDeletable: boolean;
    onClickEdit: () => void;
    onClickDelete: () => void;
}

@autoBindMethods
@observer
class CaseActivityActionFooter extends Component<IProps> {

  public render () {
    const {
        activity,
        isDeletable,
        onClickDelete,
        onClickEdit,
    } = this.props
    , {
        by_person = [],
        deleted_at,
        recorded_at,
    } = activity
    , isDeleted = !!deleted_at;

    return (
      <div className='footer'>
          <span className='date'>{formatDateTime(recorded_at)}</span>
          {by_person && by_person.first_name &&
          <span className='author'>&nbsp;{by_person.first_name}</span>
          }
          {isDeletable &&
          <LinkButton onClick={onClickEdit}>
              <Icon type='pencil' />
          </LinkButton>
          }
          {isDeletable &&
          <LinkButton onClick={onClickDelete}>
              <Icon type='trash-o' />
          </LinkButton>
          }
          {isDeleted &&
          <span className='deleted-at'>
              Deleted at {formatDate(deleted_at)}
          </span>
          }
      </div>
    );
  }
}

export default CaseActivityActionFooter;
