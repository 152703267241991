import React, { Component, Fragment } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { capitalize, get, noop, startCase } from 'lodash';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import { Button } from 'react-bootstrap';

import {
  ContentToolTip,
  FileIcon,
  Icon,
} from '../components/common';

import { AppConstants } from '../constants';
import { getFilenameFromUrl } from './util';
import FormattingUtils from './FormattingUtils';
import { IAddress, IReactTableItem } from '../interfaces';

const {
    formatAddress,
    formatDate,
    formatDateTime,
    formatDelimitedList,
    formatMoney,
    formatPercentage,
    formatPhoneNumber,
    getNameOrDefault,
    getOrDefault,
    mapBooleanToText,
    varToLabel,
  } = FormattingUtils
  , {
    CONTACT_TYPES,
    EMPTY_FIELD,
    REGISTRY_SHARING_ELIGIBILITY_STATUSES,
    TOOLTIP_ID_TABLE,
  } = AppConstants
;

const renderDate = renderFormattedCell(formatDate)
  , renderDateTime = renderFormattedCell(formatDateTime)
  , renderMoney = renderFormattedCell(formatMoney)
  , renderPercentage = renderFormattedCell(formatPercentage)
  , renderMoneyArrayCell = renderFormattedArrayCell(formatMoney)
  , renderPhoneNumberArrayCell = renderFormattedArrayCell(formatPhoneNumber)
  , renderUnformatted = renderFormattedCell(getOrDefault)
  ;

function renderFormattedCell (formatter: (value: IReactTableItem['value']) => string | null | React.ReactNode) {
  return (item: IReactTableItem) => <div className='ellipsis'>{formatter(item.value)}</div>;
}

function renderFormattedArrayCell (formatter: (value: IReactTableItem['value']) => string | null | React.ReactNode) {
  return (item: IReactTableItem) => {
    const list = item.value.map((element?: any) => formatter(element));
    return <div className='ellipsis'>{formatDelimitedList(list)}</div>;
  };
}

function renderCapitalized (item: { value?: any }) {
  return <div className='ellipsis'>{capitalize(getOrDefault(item.value))}</div>;
}

function renderName (item: { original: any }) {
  return <div className='ellipsis'>{getNameOrDefault(item.original)}</div>;
}

function renderUpdateStatus (item: { original: any}) {
  const task = get(item, 'original.latest_tracking_update_task') || {task_status: 'DEFAULT'}
    , { mainText, secondaryText } = updateStatusOptions[task.task_status](task);
  return (
    <div>
      <div className='ellipsis'>{mainText}</div>
      {secondaryText && <span className='ellipsis small-text'>{secondaryText}</span>}
    </div>
  );
}

function renderRegistrySharingEligibilityStatuses (item: { value?: string }) {
  const value = item.value ? REGISTRY_SHARING_ELIGIBILITY_STATUSES[item.value] : null;
  return <div className='ellipsis'>{getOrDefault(value)}</div>;
}

function renderBooleanYesNo (item: { value: boolean }) {
  return <div className='ellipsis'>{item.value ? 'Yes' : 'No'}</div>;
}

function renderBooleanArray (item: { value: boolean[] }) {
  const boolArr = item.value.map((boolean: boolean) => mapBooleanToText(boolean));
  return <div className='ellipsis'>{boolArr.length !== 0 ? formatDelimitedList(boolArr) : 'No'}</div>;
}

function renderOption (item: { value: any }) {
  const label = item.value ? varToLabel(item.value) : EMPTY_FIELD;
  return <div className='ellipsis' data-tip={label} data-for={TOOLTIP_ID_TABLE}>{label}</div>;
}

function renderSnakeCaseCell (item: IReactTableItem) {
  return <div className='ellipsis'>{getOrDefault(startCase(item.value))}</div>;
}

function renderBlankCell () {
  return <span>&nbsp;</span>;
}

function renderStatusCell (item: IReactTableItem) {
  return <div className='ellipsis'>{getOrDefault(startCase(item.value))}</div>;
}

function renderUnformattedArrayCell (item: IReactTableItem) {
  return <div className='ellipsis'>{formatDelimitedList(item.value)}</div>;
}

function renderStartCasedArrayCell (item: IReactTableItem) {
  const values = item.value || [];
  return <div className='ellipsis'>{formatDelimitedList(values.map(startCase))}</div>;
}

function renderIsOpen (item: IReactTableItem) {
  const value = item.value ? 'open' : 'closed';
  return (
    <div className='wrapper-status'>
      <div data-tip data-for={`item-status-${item.index}`} className={`status status-${value}`}><Icon type='circle-o' /></div>
      <ReactTooltip id={`item-status-${item.index}`} type='info' effect='solid' class='tooltip-reports-status' place='right' offset={{ top: 1, right: 2}}>
        <span>{value}</span>
      </ReactTooltip>
    </div>
  );
}

function renderAddress (item: { value?: IAddress }) {
  const address = formatAddress(item.value);

  return (
    <div className='ellipsis' data-tip={address} data-for={TOOLTIP_ID_TABLE}>
      {address}
    </div>
  );
}

function renderPhoneNumber (item: IReactTableItem) {
  const phoneNumber = formatPhoneNumber(item.value);
  return <ContentToolTip data-for={item.value ? item.column.tooltipId : null} data-tip={phoneNumber}>{phoneNumber}</ContentToolTip>;
}

function renderWarningIcon (item: any) {
  if (item.value !== null) {
    return <Icon type='exclamation-triangle' />;
  }
}

function renderFileType (item: { value?: string }) {
  return <FileIcon fileName={item.value} />;
}

function renderFilenameLink (item: IReactTableItem) {
  const viewUrl = item.column.getViewUrl(item.original);
  if (!viewUrl) { return renderUnformatted(item); }
  return <a className='ellipsis' href={viewUrl} target='_blank'>{getOrDefault(item.value)}</a>;
}

function renderFilenameFromUrl (item: { value?: string }) {
  const name = getFilenameFromUrl(item.value);
  return <div className='ellipsis'>{getOrDefault(name)}</div>;
}

function renderFileTypeFromUrl (item: { value?: string }) {
  const name = getFilenameFromUrl(item.value);
  return renderFileType({value: name});
}

function renderLawFirmAndAttorney (item: { value?: string, original: { attorney?: string } }) {
  const lawfirm = getOrDefault(item.value)
    , attorney = item.original.attorney
    , tooltipHTML = `<div>${lawfirm}</div><div>${attorney || ''}</div>`;

  return (
    <div data-tip={tooltipHTML} data-for={TOOLTIP_ID_TABLE}>
      <div className='ellipsis'>{lawfirm}</div>
      {attorney && <span className='ellipsis small-text'>{attorney}</span>}
    </div>
  );
}

function renderContactType (item: { value?: string }) {
  const type = get(CONTACT_TYPES, `${item.value}.display`, item.value);
  return <div className='ellipsis'>{getOrDefault(type)}</div>;
}

type StatusOptionCallback = (task: any) => {mainText: string, secondaryText: string | null};

const updateStatusOptions: {[key: string]: StatusOptionCallback} = {
  COMPLETED: (task: any) => ({
    mainText: `Responded at ${formatDate(task.completed_at)}`,
    secondaryText: null,
  }),
  CREATED: (task: any) => ({
    mainText: 'Awaiting Response',
    secondaryText: `Requested on ${formatDate(task.created_at)}`,
  }),
  DEFAULT: (_task: any) => ({
    mainText: EMPTY_FIELD,
    secondaryText: null,
  }),
  REMINDED: (task: any) => ({
    mainText: 'Awaiting Response',
    secondaryText: `Reminder sent on ${formatDate(task.reminded_at)}`,
  }),
};

interface IPropsSelectInputComponent {
  checked: boolean;
  id: string;
  onClick?: (id: string, shiftKey: boolean, row: object) => void;
  row: object;
  selectType?: string;
  listTitle?: string;
}

@autoBindMethods
export class SelectInputComponent extends Component<IPropsSelectInputComponent> {
  public static defaultProps: Partial<IPropsSelectInputComponent>;

  public onClick (e: any) {
    const event: MouseEvent = e
      , { id, onClick, row } = this.props
      , { shiftKey } = event as MouseEvent
      , onClickFunction = onClick ?? noop
      ;

    event.stopPropagation();
    onClickFunction(id, shiftKey, row);
  }

  private get isCheckbox () {
    return this.props.selectType === 'checkbox' || undefined;
  }

  public render () {
    const { checked, id, selectType, listTitle } = this.props
      , inputIdBase = this.isCheckbox ? 'check' : 'radio'
      , allSuffix = listTitle ? `-${listTitle}` : ''
      , inputIdSuffix = id || `all${allSuffix}`
      , inputId = `${inputIdBase}-${inputIdSuffix}`
      , className = this.isCheckbox ? 'small-checkbox' : 'radio-button'
      , classNames = cx(className, 'select-input')
      ;

    return (
      <div className={classNames}>
        <input
          checked={checked}
          id={inputId}
          onClick={this.onClick}
          type={selectType}
        />
        <label htmlFor={inputId} />
      </div>
    );
  }
}

function renderHeaderWithTooltip (id: string, header: string, tooltipText: string) {
  return (
    <div className='ellipsis'>
      <div data-tip data-for={id}>
        <Fragment>
          {header}
        </Fragment>
        <Button bsStyle='link'>
          <Icon type='question-circle-o' />
        </Button>
      </div>
      <ReactTooltip id={id} type='info' effect='solid' place='bottom' offset={{ top: 2 }}>
        <span>{tooltipText}</span>
      </ReactTooltip>
    </div>
  );
}

const CUSTOM_FIELD_TYPE_RENDERERS = {
  DATE: renderDate,
  MONEY: renderMoney,
};

export default {
  CUSTOM_FIELD_TYPE_RENDERERS,
  renderAddress,
  renderBlankCell,
  renderBooleanArray,
  renderBooleanYesNo,
  renderCapitalized,
  renderContactType,
  renderDate,
  renderDateTime,
  renderFilenameFromUrl,
  renderFilenameLink,
  renderFileType,
  renderFileTypeFromUrl,
  renderHeaderWithTooltip,
  renderIsOpen,
  renderLawFirmAndAttorney,
  renderMoney,
  renderMoneyArrayCell,
  renderName,
  renderOption,
  renderPercentage,
  renderPhoneNumber,
  renderPhoneNumberArrayCell,
  renderRegistrySharingEligibilityStatuses,
  renderSnakeCaseCell,
  renderStartCasedArrayCell,
  renderStatusCell,
  renderUnformatted,
  renderUnformattedArrayCell,
  renderUpdateStatus,
  renderWarningIcon,
  SelectInputComponent,
};
