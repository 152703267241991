const LABEL = { required: true, field: 'label' }
, INPUT_FILE = { required: true, field: 'input_file', type: 'file' }
, CONFIG = {
    field: 'configuration',
    label: 'Template',
    optionType: 'importConfigurations',
    required: true,
    type: 'optionSelect',
};

export const CREATE_IMPORT = [[LABEL, CONFIG, INPUT_FILE]]
  , UPDATE_IMPORT = [[LABEL, INPUT_FILE]]
  , RESTART_IMPORT = [[INPUT_FILE]]
  ;
