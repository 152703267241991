import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { MenuItem } from 'react-bootstrap-typeahead';
import cx from 'classnames';

import FormattingUtils from '../../../../utils/FormattingUtils';
import { FunderStoreClass, OptionsStoreClass } from '../../../../stores';

import {
  getBaseSearchResultDisplayItem,
  IDisplaySearchResultItemConfiguration,
  ISearchResultItemProps,
} from './SearchResultUtils';

const { formatDate, getNameOrDefault, getOrDefault } = FormattingUtils;

interface IProps extends ISearchResultItemProps {
  FunderStore: FunderStoreClass;
  OptionsStore: OptionsStoreClass;
}

@inject(
  'FunderStore',
  'OptionsStore',
)
@autoBindMethods
@observer
class PlaintiffSearchResultItem extends Component<IProps> {

  public render () {
    const { item, FunderStore, OptionsStore } = this.props
      , displayItemConfiguration: IDisplaySearchResultItemConfiguration = {
        ...this.props,
        entity: 'plaintiff',
        path: '/case/',
      }
      , canSeeVanityIds = FunderStore.canSeeVanityIds
      , caseType = getOrDefault(OptionsStore.getCaseTypeOptionById(item.type).name)
      , dateOfLoss = formatDate(item.date_of_loss)
      , displayItem = getBaseSearchResultDisplayItem(displayItemConfiguration)
      , lawfirm = getNameOrDefault(item.lawfirm)
      , plaintiffName = getNameOrDefault(item.plaintiff)
      , stateName = OptionsStore.formatStateName(item);

    return (
      <MenuItem key={item.id} option={displayItem} position={item.index}>
        <div className='search-result-item'>
          <p>
            {canSeeVanityIds && <span className='pull-right'>Case ID: {displayItem.vanityId}</span>}
            <strong className='fs-mask'>{plaintiffName}</strong>
          </p>
          <p>
            <span className={cx('pull-right', 'fs-mask')}>Date of Loss: {dateOfLoss}</span>
            {caseType}, {lawfirm} {stateName && <span>({stateName})</span>}
          </p>
        </div>
      </MenuItem>
    );
  }
}

export default PlaintiffSearchResultItem;
