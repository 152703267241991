import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { find } from 'lodash';

import { Well } from 'react-bootstrap';

import { ActionButton, DeleteButton, DocumentTable, DocumentUploadModal, Icon } from '../../common';
import { ConfirmDeletePopover } from '../../common-popovers';
import { AppConstants } from '../../../constants';
import { createGuardedContainer, createDisabledContainer } from '../../../containers';
import { CaseStoreClass, LienStore as LienStoreClass, SessionStoreClass } from '../../../stores';
import { TableRenderingUtils, SmartBool } from '../../../utils';
import { ICase, IDocument } from '../../../interfaces';

const {
  renderDate,
  renderFilenameLink,
  renderFileType,
} = TableRenderingUtils;

interface IProps {
  _case: ICase;
}

interface IInjected extends IProps {
  CaseStore: CaseStoreClass;
  LienStore: LienStoreClass;
  SessionStore: SessionStoreClass;
}

const { PERMISSIONS } = AppConstants;

const GuardedDeleteButton = createGuardedContainer({
  disabledComponent: createDisabledContainer(DeleteButton),
  enabledComponent: DeleteButton,
  permissionName: PERMISSIONS.DELETE_LIEN_DOCUMENTS,
});

@inject('CaseStore', 'LienStore', 'SessionStore')
@autoBindMethods
@observer
class CaseLienDocuments extends Component<IProps> {
  @observable private documents: IDocument[] = [];
  @observable private refTable = null;
  @observable private uploadModalShowing = new SmartBool(false);
  private currentDocument: any;

  public async componentDidMount () {
    const { _case } = this.props
      , { LienStore } = this.injected;

    this.documents = await LienStore.listCaseLienDocuments(_case.id);
  }

  private get injected () {
    return this.props as IInjected;
  }

  private get columns () {
    const { CaseStore, SessionStore } = this.injected
      , hasEditPermission = SessionStore.userHasPermission(PERMISSIONS.EDIT_LIEN_DOCUMENTS);
    return [
      {
        accessor: 'file',
        Cell: renderFileType,
        className: 'col-file',
        Header: null,
        width: 25,
      },
      {
        accessor: 'name',
        Cell: renderFilenameLink,
        getViewUrl: SessionStore.getDocumentViewUrl,
        Header: 'Name',
        minWidth: 150,
        sortable: true,
      },
      {
        accessor: 'created_at',
        Cell: renderDate,
        className: 'col-date col-style-gray',
        Header: 'Date',
        sortable: true,
        width: 100,
      },
      {
        accessor: 'id',
        Cell: (row: any) => {
          const { column: { overlayContainer } } = row;
          return (
            <div>
              {hasEditPermission &&
                <ActionButton className='btn-edit-type' data={row.original} onClick={this.onEditDocument}>
                  <Icon type='pencil' />
                </ActionButton>
              }
              <ActionButton className='btn-download' data={row.original.download_url} onClick={CaseStore.onGenerateUrlDocumentDownloadClick}>
                <Icon type='download' />
              </ActionButton>
              <ConfirmDeletePopover
                container={overlayContainer}
                data={row.value}
                name='document'
                onDelete={this.onClickDelete}
                placement='left'
                rootClose
              >
                <GuardedDeleteButton />
              </ConfirmDeletePopover>
            </div>
          );
        },
        className: 'col-buttons',
        Header: null,
        overlayContainer: this.refTable,
        width: 100,
      },
    ];
  }

  private setTableRef (ref: null) {
    this.refTable = ref;
  }

  private onEditDocument (data: { id: string; }) {
    const _document = find(toJS(this.documents), {id: data.id});
    this.currentDocument = _document;
    this.uploadModalShowing.setTrue();
  }

  private async handleDocumentUploadSubmit (data: { id: string; }) {
    const { _case, LienStore } = this.injected;
    if (data.id) {
      await LienStore.updateLienDocument(data.id, data);
      this.currentDocument = null;
      this.documents = await LienStore.listCaseLienDocuments(_case.id);
    }
  }

  private async onClickDelete (lienDocumentId: string) {
    const { _case, LienStore } = this.injected
         , document = find(this.documents, { id: lienDocumentId }) as undefined | IDocument;
    if (document === undefined) { return; }

    await LienStore.deleteLienDocument(document.lien, _case.id, lienDocumentId);
    this.documents = this.documents.filter(d => d.id !== document.id );
  }

  public render () {
    const { _case } = this.props;

    return (
      <Well>
        <DocumentTable
          documents={toJS(this.documents)}
          downloadAllUrl={_case.download_all_lien_docs_url}
          columns={this.columns}
          hasHeader
          title='Lien Documents'
          ref={this.setTableRef}
        />
        {this.uploadModalShowing.isTrue &&
            <DocumentUploadModal
              _document={this.currentDocument}
              onClose={this.uploadModalShowing.setFalse}
              onSubmit={this.handleDocumentUploadSubmit}
            />
          }
      </Well>
    );
  }
}

export default CaseLienDocuments;
