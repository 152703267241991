/* eslint-disable sort-keys */
import { insertIf } from '../../../utils/util';
import { IFieldSetPartial } from '../../../lib/mighty-fields';
import LienInfoEditableCard from '../LienInfoEditableCard';

interface IOptions {
  finalize?: boolean;
  isFinanceCustomer?: boolean;
  isMedicalProvider?: boolean;
  showIsOpen?: boolean;
  canCloseLiens?: boolean;
}

const fieldSetsMedicalApplication = (options: IOptions): IFieldSetPartial[] => {
  const { canCloseLiens, finalize, isMedicalProvider, showIsOpen, isFinanceCustomer } = {
    canCloseLiens: false,
    finalize: false,
    isFinanceCustomer: false,
    isMedicalProvider: false,
    showIsOpen: false,
    ...options,
  };

  return [
    ...insertIf(showIsOpen, LienInfoEditableCard.fieldSet({isFinanceCustomer, finalize, canCloseLiens})),
    [
      { field: 'label', required: true },
    ],
    [
      { field: 'initial_service_date', type: 'date', required: finalize },
      { field: 'end_service_date', type: 'date' },
      ...insertIf(!isMedicalProvider, { field: 'purchase_date', type: 'date', required: finalize }),
      { field: 'notes', type: 'text' },
      ...insertIf(!showIsOpen && isFinanceCustomer, { field: 'is_available_to_coop', label: 'Available To CoOp Capital', type: 'boolean', required: true }),
    ],
  ];
};

const FIELD_SETS_LEGAL_APPLICATION: IFieldSetPartial[] = [[{ field: 'requested_amount', type: 'money', required: true }]];

export {
  FIELD_SETS_LEGAL_APPLICATION,
  fieldSetsMedicalApplication,
};
