import React, { Component } from 'react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Button, Col, Row, Well } from 'react-bootstrap';

import ClientsClass from '../../../clients/ClientsClass';
import { AppConstants } from '../../../constants';
import { FormattingUtils } from '../../../utils';

import { DownloadButton, InfoWell, Loader, Page } from '../../common';
import DataAdminHeader from '../DataAdminHeader';

const { EXPORT_CHECK_INTERVAL, EXPORT_STATUS, EXPORT_TYPES } = AppConstants
  , { formatDate } = FormattingUtils
  , FILE_DOWNLOAD_INTERVAL = 2 * 1000
  ;

interface IInjected {
  Clients: ClientsClass;
}

@inject('Clients')
@autoBindMethods
@observer
class ExportsPage extends Component {
  @observable private isLoading: boolean = true;
  @observable private isExporting: boolean = false;
  @observable private exportStatus: string = '';
  @observable private createdDate: string = '';
  @observable private files: any[] = [];
  private pollInterval?: any;

  private get injected () {
    return this.props as IInjected;
  }

  public async componentDidMount () {
    await this.syncExportAndFiles();

    this.isLoading = false;
  }

  // istanbul ignore next
  public componentWillUnmount () {
    if (!!this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }

  private async syncExportAndFiles () {
    try {
      const response = await this.injected.Clients.exports.list();

      if (response.results.length > 0) {
        this.exportStatus = response.results[0].status;
        this.createdDate = response.results[0].created_at;

        if (EXPORT_STATUS[this.exportStatus] !== EXPORT_STATUS.COMPLETE && EXPORT_STATUS[this.exportStatus] !== EXPORT_STATUS.FAILED) {
          this.isExporting = true;
          this.files = [];

          if (!this.pollInterval) {
            this.pollInterval = setInterval(
              () => this.pollExportStatus(),
              EXPORT_CHECK_INTERVAL,
            );
          }
        } else {
          this.isExporting = false;
          this.updateExportFileUrls();
        }
      } else {
        this.exportStatus = '';
        this.createdDate = '';
      }
    // tslint:disable-next-line no-empty
    } catch (err) {}
  }

  private async updateExportFileUrls () {
    const { Clients } = this.injected;

    if (EXPORT_STATUS[this.exportStatus] === EXPORT_STATUS.COMPLETE ) {
      try {
        const response = await Clients.exportsDownload.list();
        this.files = response.files;
      // tslint:disable-next-line no-empty
      } catch (err) { }
    } else {
      this.files = [];
    }
  }

  private async pollExportStatus () {
    try {
      await this.syncExportAndFiles();

      if (!this.isExporting) {
        this.endPoll();
      }
      // tslint:disable-next-line no-empty
    } catch (err) {
      this.exportStatus = EXPORT_STATUS.FAILED;

      this.endPoll();
    }
  }

  private endPoll () {

    clearInterval(this.pollInterval);
    this.pollInterval = null;
  }

  private async triggerExport () {
    const { Clients } = this.injected;

    this.isExporting = true;
    this.exportStatus = 'Starting';

    await Clients.exports.create();
    await this.syncExportAndFiles();
  }

  private formatFileName (name: string) {
    return name.slice(name.lastIndexOf('/') + 1);
  }

  private renderExportInfo () {
    const COL_WIDTHS = { label: 3 };

    return (
      <Well className='exports-well center-block maxwidth-lg'>
        <h3>Current Export</h3>
        <Row>
          <Col className='col-label' xs={COL_WIDTHS.label}>
            <strong>Status:</strong>{' '}
            {this.exportStatus ? EXPORT_STATUS[this.exportStatus] : '---'} {this.isExporting ? <span className='spinner'/> : null}
          </Col>
          <Col className='col-label' xs={COL_WIDTHS.label}>
            <strong>Generated on:</strong>{' '}
            {this.createdDate ? formatDate(this.createdDate) : '---'}
          </Col>
        </Row>
      </Well>
    );
  }

  private renderExportFiles () {
    const COL_WIDTHS = { type: 3, title: 9, name: 6, download: 3 };

    return (
      <Well className='exports-well center-block maxwidth-lg'>
        <Row>
          <Col className='col-value' xs={COL_WIDTHS.title}>
            <h3>Files</h3>
          </Col>
          <Col className='col-actions' xs={COL_WIDTHS.download}>
            <div className='flex-right'>
              {this.renderDownloadAllButton()}
            </div>
          </Col>
        </Row>
        <InfoWell>
          {this.files.length > 0 ? (
            this.files.map((file) => (
              <Row key={file.name}>
                <Col className='col-value' xs={COL_WIDTHS.type}>
                  {EXPORT_TYPES[file.type]}
                </Col>
                <Col className='col-value' xs={COL_WIDTHS.name}>
                  {this.formatFileName(file.name)}
                </Col>
                <Col className='col-actions' xs={COL_WIDTHS.download}>
                  <div className='flex-right'>
                    {this.renderDownloadExportButton(file)}
                  </div>
                </Col>
              </Row>
            ))
          ) : (
            <Row>
              <Col className='col-value' xs={COL_WIDTHS.type}>
                ---
              </Col>
              <Col className='col-value' xs={COL_WIDTHS.name}>
                ---
              </Col>
              <Col className='col-actions' xs={COL_WIDTHS.download} />
            </Row>
          )}
        </InfoWell>
      </Well>
    );
  }

  private handleDownload (file: any) {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    link.click();
  }

  private renderDownloadExportButton (file: any) {
    return (
      // tslint:disable-next-line jsx-no-lambda
      <DownloadButton className='btn-download' onClick={() => this.handleDownload(file)}>Download</DownloadButton>
    );
  }

  private renderDownloadAllButton () {
    const downloadAll = () => this.files.forEach((file, indx) => setTimeout(() => this.handleDownload(file), indx * FILE_DOWNLOAD_INTERVAL));

    return (
      // tslint:disable-next-line jsx-no-lambda
      <DownloadButton className='btn-download-all-files' onClick={() => downloadAll()}>Download All</DownloadButton>
    );
  }

  private renderStartExportButton () {
    return (
      <Button
        bsSize='small'
        bsStyle='primary'
        className='btn-export'
        disabled={this.isExporting}
        onClick={this.triggerExport}
      >
        Generate New Export
      </Button>
    );
  }

  public render () {
    return (
      <Page name='exports' type='detail'>
        <Helmet title='Exports' />
        <Page.Content>
          <DataAdminHeader title='Exports' />
          <div className='main'>
            <Row>
              <Col className='col-main' xs={12}>
                {this.isLoading ? (
                  <Loader className='bloc-loader' />
                ) : (
                  <div>
                    {this.renderExportInfo()}
                    {this.renderExportFiles()}
                    <div className='maxwidth-lg center-block'>
                      <div className='flex-right'>
                        {this.renderStartExportButton()}
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default ExportsPage;
