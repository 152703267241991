import React, { Component, Fragment } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import {
  Button,
  ButtonToolbar,
  Col,
  Grid,
  Modal,
  Row,
} from 'react-bootstrap';

import FormFieldSets from './FormFieldSets';
import FormManager from './FormManager';
import QuickAddButton from './QuickAddButton';
import { fillInFieldSets, filterFieldSets } from './common';

import Form from '../../components/common-formsy/Form';
import { IFieldSetPartial } from './interfaces';

interface IProps {
  cancelText?: string;
  childrenAfter?: React.ReactNode[] | React.ReactNode;
  childrenBefore?: React.ReactNode[] | React.ReactNode;
  className?: any;
  fieldSets: IFieldSetPartial[];
  footer?: null | React.ReactNode;
  model?: object;
  onClose: () => void;
  onSave: (model: object) => void;
  quickAdd?: any[];
  quickAddFormat?: (...args: any[]) => any;
  quickAddOnSelect?: (model: object) => void;
  quickAddTitle?: string;
  saveText?: string;
  title: string;
  wrapperName?: string;
}

interface IPropDefaults extends IProps {
  cancelText: string;
  model: object;
  saveText: string;
}

@autoBindMethods
@observer
class FormModal extends Component<IProps, {}> {
  private formManager: FormManager;

  public static defaultProps: Partial<IProps> = {
    cancelText: 'Cancel',
    model: {},
    saveText: 'Save',
  };

  get propsWithDefaults () {
    return this.props as IPropDefaults;
  }

  constructor (props: IProps) {
    super(props);
    this.formManager = new FormManager({
      fieldSets: this.fieldSets,
      model: props.model,
      onSave: props.onSave,
      onSuccess: props.onClose,
      wrapperName: props.wrapperName,
    });
  }

  @computed
  get fieldSets () {
    return filterFieldSets(fillInFieldSets(this.props.fieldSets), { readOnly: false });
  }

  private renderForm () {
    return (
      <Fragment>
        <FormFieldSets
          fieldSets={this.fieldSets}
          {...this.formManager.formGroupsProps}
        />

        {this.formManager.errorMessages.map(error => (
          <Row className='text-danger' key={error.field}>
            <strong>{error.field}</strong> - {error.message}
          </Row>
        ))}
      </Fragment>
    );
  }

  private renderQuickAdd () {
    const {
      quickAdd,
      quickAddFormat,
      quickAddOnSelect,
      quickAddTitle,
    } = this.propsWithDefaults;

    if (!quickAdd || !quickAddOnSelect) {
      // istanbul ignore next
      return;
    }

    return (
      <div className='lawfirms'>
        {quickAddTitle && <h4>{quickAddTitle}</h4>}

        <ul className='list-btn-link'>
          {quickAdd.map(object => (
            <QuickAddButton
              key={object.id}
              object={object}
              onSelect={quickAddOnSelect}
              quickAddFormat={quickAddFormat}
            />
          ))}
        </ul>
      </div>
    );
  }

  private renderGrid () {
    if (this.props.quickAdd) {
      return (
        <Grid className='col-wrapper' fluid>
          <Row>
            <Col className='col-left' sm={4}>
              {this.renderQuickAdd()}
            </Col>
            <Col className='col-right' sm={8}>
              {this.renderForm()}
            </Col>
          </Row>
        </Grid>
      );
    }
    return <Grid fluid>{this.renderForm()}</Grid>;
  }

  private renderFooter () {
    const { cancelText, footer, onClose } = this.props
      , { saveText } = this.propsWithDefaults
      ;

    if (!!footer) {
      return footer;
    }

    return (
      <ButtonToolbar className='pull-right'>
        <Button onClick={onClose}>{cancelText}</Button>
        <Button
          bsStyle='primary'
          disabled={this.formManager.submitDisabled()}
          type='submit'
        >
          {this.formManager.isSubmitting ? 'Saving...' : saveText}
        </Button>
      </ButtonToolbar>
    );
  }

  public render () {
    const {
        children,
        childrenAfter,
        childrenBefore,
        onClose,
        title,
      } = this.props
      , className = cx(this.props.className, 'over', {
        'two-col': !!this.props.quickAdd,
      });

    return (
      <Modal
        show
        onHide={onClose}
        className={className}
        backdropClassName='over'
      >
        <Form {...this.formManager.formProps}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {childrenBefore}
            {this.renderGrid()}
            {children}
            {childrenAfter}
          </Modal.Body>

          <Modal.Footer>
            {this.renderFooter()}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default FormModal;
