import { uniqBy, isObject, isEmpty, has, get, assign, omit, pick } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';
import { action, observable, toJS } from 'mobx';

import FormDataUtils from '../utils/FormDataUtils';
import { AppConstants } from '../constants';
import ActivityStore from './ActivityStore';
import FlatStore from './FlatStore';
import { ICase, IAggregateSchedule, ITransport } from '../interfaces';
import { CaseModel } from '../models';
import { isTransportErrorNotFound } from '../utils/transportErrorUtils';

const MVA_CASE_TYPE = 11;

const {
  MODEL_TYPES,
} = AppConstants;

interface IQueryResults {
  hasNextPage: boolean;
  items: ICase[];
  nextPageUrl: string | null;
  totalItems: number;
}

interface IStateMVAThreshold {
  comparative_negligence: string;
  pip_information: string;
  pip_limited_by: string;
  pip_per_accident_coverage: string | number;
  pip_per_person_coverage: number;
  unlimited_pip_coverage: boolean;
}

interface IStatuteOfLimitations {
  date_of_expiration: string;
  state: string;
  time_to_expiration: string;
}

interface ICaseListResponse {
  count: number;
  next: null | string;
  previous: null | string;
  results: ICase[];
}

@autoBindMethods
class CaseStoreClass {
  @observable public queryResults: { [key: string]: IQueryResults } = {};
  @observable public searchFiltersMeta: object[] = [];
  @observable public stateMVAThreshold: { [key: string]: IStateMVAThreshold } = {};
  @observable public statuteOfLimitations: { [key: string]: IStatuteOfLimitations } = {};
  @observable public tabCounts: { [key: string]: number } = {};

  public caseTrackingStatus: ActivityStore;
  public caseActivities: ActivityStore;
  public caseSms: ActivityStore;

  public flatStore: FlatStore;
  public transport: ITransport;

  constructor (flatStore: FlatStore, transport: ITransport) {
    this.flatStore = flatStore;
    this.transport = transport;

    this.caseActivities = new ActivityStore(transport, {});
    this.caseTrackingStatus = new ActivityStore(transport, {
      page_size: 1,
      type: MODEL_TYPES.caseTrackingUpdate.key,
    });
    this.caseSms = new ActivityStore(transport, {
      type: MODEL_TYPES.caseSms.key,
    });
  }

  @action
  public async update ({ caseId, data }: { caseId: string, data: { [key: string]: any } }) {
    const patchData = toJS(data);

    if (isObject(patchData.lawfirm)) {
      patchData.lawfirm = patchData.lawfirm.id;
    }

    // Update from patched data
    await this.transport.patch('/cases/:caseId/', { data: patchData, urlParams: { caseId }});

    return (await this.refresh(caseId));
  }

  @action
  public async updateCaseTrackingStatus (caseId: string, data: { [key: string]: any }) {
    const caseData = {
        tracking_follow_up_date: data.trackingFollowUpDate,
      }
      , activityData = {
        body: data.body,
        case: caseId,
        medical_status: data.medical_status,
        tracking_attributes: data.tracking_attributes,
        tracking_status: data.tracking_status,
      }
      , lastTrackingStatus = get(this.caseTrackingStatus, 'response.results[0]', null)
    ;

    if (lastTrackingStatus && data.tracking_attributes.includes('no_update')) {
      activityData.medical_status = activityData.medical_status || lastTrackingStatus.medical_status;
      activityData.tracking_status = activityData.tracking_status || lastTrackingStatus.tracking_status;
    }

    const request = Promise.all([
      this.transport.post('/case-tracking-updates/', { data: activityData }),
      this.update({ caseId, data: caseData }),
    ]);

    await Promise.all([
      this.caseActivities.loadNew(MODEL_TYPES.caseTrackingUpdate.key, request),
      this.caseTrackingStatus.loadNew(MODEL_TYPES.caseTrackingUpdate.key, request),
    ]);
  }

  @action
  public async upsertUnderwriterSummaryAnalysis (caseId: string, data: object) {
    const _case = this.flatStore.case.get(caseId);

    isEmpty(_case.underwriter_case_summary_analyses)
      ? await this.transport.post('/underwriter-summary-analyses/', { data: {...data, case: caseId} })
      : await this.transport.patch('/underwriter-summary-analyses/:caseId/', { data, urlParams: { caseId }});
    this.refresh(caseId);
  }

  public store (data: { [key: string]: any }): CaseModel {
    if (has(data, 'lead_source.id')) {
      const leadSource = this.flatStore.leadSource.store(data.lead_source);
      data.lead_source = leadSource.id;
    }

    return this.flatStore.case.store(data);
  }

  @action
  public async create ({ data }: { data: object }) {
    const response = await this.transport.post('/cases/', { data })
      , _case = await this.retrieve(response.id, {});
    return _case;
  }

  @action
  public async refresh (id: string) {
    const data = await this.transport.get('/cases/:id/', { urlParams: { id } });
    return this.store(data);
  }

  @action
  public async retrieve (id: string, options: { [key: string]: any } = {}) {
    const [data] = await Promise.all([
        this.transport.get('/cases/:id/', { urlParams: { id }, ...options }),
        this.retrieveCaseSettlementOffer(id),
        this.fetchTabCounts(id),
      ])
      , _case = this.store(data);

    this.caseTrackingStatus.updateParams({ case: id });
    this.caseActivities.updateParams({ case: id });
    this.caseSms.updateParams({ case: id });

    if (options.caseOnly) { return _case; }

    return _case;
  }

  @action
  public async delete (id: string) {
    return this.transport.delete('/cases/:id/', { urlParams: { id } });
  }

  @action
  public async fetchTabCounts (id: string) {
    const data = await this.transport.get('/cases/:id/counts/', { urlParams: { id } });
    this.tabCounts = data;
    return this.tabCounts;
  }

  @action
  public async fetchStateMVAThreshold (_case: ICase) {
    if (_case.type === MVA_CASE_TYPE && _case.state_of_incident) {
      const state = _case.state_of_incident;
      try {
        this.stateMVAThreshold = await this.transport.get('/state-mva-thresholds/:state/', {urlParams: {state}});
      }
      catch (error) {
        if (isTransportErrorNotFound(error)) {
          this.stateMVAThreshold = {};
        }
        else {
          throw error;
        }
      }
    }
    else {
      this.stateMVAThreshold = {};
    }
  }

  @action
  public async fetchStatuteOfLimitations (_case: ICase) {
    if (_case.type && _case.state_of_incident) {
      const caseId = _case.id;
      this.statuteOfLimitations = await this.transport.get('/statutes-of-limitations/:caseId/', { urlParams: { caseId } });
    }
    else {
      this.statuteOfLimitations = {};
    }
  }

  @action
  public async fetchAggregateSchedule (caseId: string): Promise<IAggregateSchedule[]> {
    return await this.transport.get('/cases/:caseId/aggregate_schedule/', { urlParams: { caseId } });
  }

  @action
  public async applyLeadSource (caseId: string, data: { [key: string]: any }) {
    let leadSource = data;
    if (!leadSource.id) {
      // Create new lead source
      leadSource = await this.transport.post('/lead-sources/', { data });
    }
    leadSource = this.flatStore.leadSource.store(leadSource);
    const _case = this.flatStore.case.get(caseId);

    await this.transport.patch('/cases/:caseId/', {
      data: { lead_source: leadSource.id },
      urlParams: { caseId },
    });
    _case.lead_source = leadSource.id;
  }

  @action
  public async deleteCaseActivity (activity: { [key: string]: any, id: string }) {
    const id = activity.id
      , response = await this.transport.delete('/case-activities/:id/', { urlParams: { id } });
    assign(activity, response);
  }

  @action
  public async retrieveCaseSettlementOffer (id: string) {
    const response = await this.transport.get('/case-settlement-offers/', { params: { case: id } })
      , _case = this.flatStore.case.get(id);
    _case.case_settlement_offer = !isEmpty(response.results) ? response.results[0] : {};
  }

  @action
  public async createCaseNote ({ data }: { data: object }) {
    const request = this.transport.post('/case-notes/', { data });
    await this.caseActivities.loadNew(MODEL_TYPES.caseNote.key, request);
  }

  @action
  public async editCaseNote (note: { [key: string]: any, id: string }) {
    const id = note.id
      , request = this.transport.patch('/case-notes/:id/', { data: note, urlParams: { id }});
    return this.caseActivities.loadNew(MODEL_TYPES.caseNote.key, request);
  }

  @action
  public async createGeneratedEmail (data: { [key: string]: any }) {
    const formData = FormDataUtils.toForm({
      attachments: data.files, // Note the rename here
      bcc_emails: data.bcc_emails,
      case: data.case,
      cc_emails: data.cc_emails,
      generated_email: data.generated_email,
      subject: data.subject,
      template: data.template,
      to_emails: data.to_emails,
    });
    const request = this.transport.post('/generated-emails/', { data: formData });
    await this.caseActivities.loadNew(MODEL_TYPES.generatedEmail.key, request);
  }

  @action
  public async fetchCaseDocuments (id: string) {
    const response = await this.transport.get('/case-documents/', { params: { case: id } });
    this.flatStore.case.get(id).extend({ documents: response });
  }

  @action
  public async createCaseDocument ({ caseId, data }: { caseId: string, data: object }) {
    const form = FormDataUtils.toForm({ case: caseId, ...data });
    await this.transport.post('/case-documents/', { data: form });
    this.fetchCaseDocuments(caseId);
    this.fetchTabCounts(caseId);
  }

  @action
  public async updateCaseDocument ({caseId, id, data}: { caseId: string, id: string, data: object }) {
    const form = FormDataUtils.toForm(data);
    await this.transport.patch('/case-documents/:id/', { data: form, urlParams: { id }});
    this.fetchCaseDocuments(caseId);
  }

  @action
  public async deleteCaseDocument ({ caseId, id }: { caseId: string, id: string }) {
    const request = this.transport.delete('/case-documents/:id/', { urlParams: { id } });
    const _case = this.flatStore.case.get(caseId)
      , document = _case.documents.find((doc: { id: string }) => (doc.id === id));
    _case.documents.remove(document);
    await this.caseActivities.loadNew(MODEL_TYPES.deletedCaseDocument.key, request);
    this.fetchTabCounts(caseId);
  }

  @action
  public async updateCaseSettlementAmount ({ caseId, data }: { caseId: string, data: { [key: string]: any } }) {
    const _case = this.flatStore.case.get(caseId)
    , { settlement_amount, settlement_date } = await this.transport.patch('/cases/:caseId/', { data, urlParams: { caseId }});

    _case.settlement_amount = settlement_amount;
    _case.settlement_date = settlement_date;

    return (await this.refresh(caseId));
  }

  @action
  public async upsertCaseSettlementOffer ({data}: { data: { [key: string]: any } }) {
    const _case = this.flatStore.case.get(data.case)
      , settlementOffer = isEmpty(_case.case_settlement_offer)
        ? await this.transport.post('/case-settlement-offers/', {data})
        : await this.transport.put('/case-settlement-offers/:id/', { data, urlParams: {id: _case.case_settlement_offer.id} });

    _case.case_settlement_offer = settlementOffer;
    return _case.case_settlement_offer;
  }

  @action
  public setPlaintiff ({ caseId, plaintiff }: { caseId: string, plaintiff: object }) {
    this.flatStore.case.get(caseId).extend({ plaintiff });
  }

  @action
  public setLawFirm ({ caseId, lawfirm }: { caseId: string, lawfirm: object }) {
    this.flatStore.case.get(caseId).extend({ lawfirm });
  }

  @action
  public setDefendant ({ caseId, defendant }: { caseId: string, defendant: object }) {
    // assuming one defendant per case
    this.flatStore.case.get(caseId).extend({ defendants: [defendant] });
  }

  @action
  public async list (key: string, params: object) {
    // the trailing slash is actually very important, otherwise it will redirect
    const response = await this.transport.get('/cases/', { params });
    this._addCaseListQueryResults({ clear: true, key, response });
    return this.queryResults[key];
  }

  @action
  public async listSearchResults (key: string, params: object) {
    const response = await this.transport.get('/case-search/', { params }) as ICaseListResponse;
    this._addCaseListQueryResults({ clear: true, key, response });
    return this.queryResults[key];
  }

  @action
  public async fetchCasesQueryResultsNext (key: string) {
    const queryResult = this.queryResults[key];
    if (!queryResult || !queryResult.hasNextPage) {
      return;
    }

    if (!queryResult.nextPageUrl) { return; }
    const response = await this.transport.get(queryResult.nextPageUrl);
    this._addCaseListQueryResults({ key, response });

    return this.queryResults[key];
  }

  public async fetchAttorneys (lawfirmId: string) {
    const results = await this.transport.getAll(
      '/attorneys/', { params: { case_is_open: 'True', lawfirm: lawfirmId }});
    return results;
  }

  @action
  public async fetchCaseSearchFiltersMeta (params: object) {
    const pickedParams = omit(params, ['app_status', 'case_is_open', 'ordering']);
    this.searchFiltersMeta = await this.transport.get('/cases/search_filter_count/', { params: pickedParams});
  }

  public async onCasePayoffDownloadClick (_case: ICase, payoffDate: string, templateId: string) {
    const {
        payoff_download_url,
      } = _case
      , options = { params: { payoff_date: payoffDate, template_id: templateId } }
      , errorMessage = 'Unable to generate payoff...'
      ;

    if (!payoff_download_url) { return; }
    const request = this.transport.getDocument(payoff_download_url, undefined, { errorMessage, options });
    await this.caseActivities.loadNew(MODEL_TYPES.caseGeneratedDocxActivity.key, request);
  }

  public async onCasePayoffFaxClick (_case: ICase, model: { payoff_date: string }, templateId: string) {
    const { payoff_download_url } = _case
      , params = { payoff_date: model.payoff_date, template_id: templateId }
      , data = pick(model, ['fax_number', 'fax_name', 'fax_subject', 'fax_comments'])
      ;

    if (!payoff_download_url) { return; }
    await this.transport.post(payoff_download_url, { data, params });
  }

  public async onCasePayoffEmailClick (_case: ICase, model: { payoff_date: string }, templateId: string) {
    const params = { payoff_date: model.payoff_date, template_id: templateId };

    return await this.transport.post(`/backend/cases/:id/payoff-pdf/`, { params, urlParams: {id: _case.id} });
  }

  public onCaseLienContractDownloadClick (contractUrl: string, _caseId: string) {
    this.transport.getDocument(contractUrl, undefined, {errorMessage: 'Unable to generate contract...'});
  }

  public onCaseGeneratedDocxActivityDownloadClick (_caseId: string, url: string, filename: string) {
    const errorMessage = `Unable to download '${filename}', please chat us or email support@mighty.com to resolve this issue.`;
    this.transport.getDocument(url, undefined, {errorMessage});
  }

  public onDocumentDownloadClick (url: string, filename?: string) {
    this.transport.getDocument(url, filename, {errorMessage: 'Unable to generate document...'});
  }

  public onGenerateUrlDocumentDownloadClick (url: string) {
    this.transport.downloadFromS3URL(url, {errorMessage: 'Unable to generate document...'});
  }

  public async faxDocumentTemplate (url: string, formData: object) {
    const data = pick(formData, ['fax_number', 'fax_name', 'fax_subject', 'fax_comments']);
    await this.transport.post(url, { data });
  }

  @action
  public _addCaseListQueryResults ({ key, response, clear = false }: { key: string, response?: ICaseListResponse, clear?: boolean }) {
    if (!response) { return; }
    const { count, results, next } = response;
    let queryResult = this.queryResults[key];

    const cases = results.map((_case: ICase) => this.store(_case));

    if (!queryResult) {
      queryResult = this.queryResults[key] = {
        hasNextPage: false,
        items: [],
        nextPageUrl: null,
        totalItems: 0,
      };
    }

    /* tslint:disable prefer-object-spread */
    this.queryResults[key] = Object.assign({}, queryResult, {
      hasNextPage: !!next,
      items: clear
        ? cases
        : uniqBy(queryResult.items.concat(cases), 'id'),
      nextPageUrl: next,
      totalItems: count,
    });
    /* tslint:enable prefer-object-spread */
  }

  public async fetchEmailDetails (emailId: string) {
    return (
      await this.transport.get('/generated-emails/:emailId/', { urlParams: { emailId } })
    );
  }

  @action
  public downloadSentFax (url: string) {
    const errorMessage = 'Unable to download the sent fax. Please contact support to resolve this issue.';
    this.transport.getDocument(url, undefined, {errorMessage});
  }

  @action
  public async sendSms (data: object) {
    const request = this.transport.post('/case-sms/', { data });
    await this.caseSms.loadNew(MODEL_TYPES.caseSms.key, request);
  }

  @action
  public async sendBulkCaseUploadRequest (uploadFiles: object) {
    const data = FormDataUtils.toForm({ upload_files: uploadFiles });
    await this.transport.post('/bulk-case-upload-requests/', { data });
  }
}

export default CaseStoreClass;
