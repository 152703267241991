import { action, observable } from 'mobx';
import httpStatus from 'http-status-codes';
import autoBindMethods from 'class-autobind-decorator';
import { has, get } from 'lodash';

import AppState from '../lib/AppState';
import { AppConstants } from '../constants';

import Storage from './Storage';
import { IAuthenticator, ITransport } from '../interfaces';

interface IJwt {
  expires: number;
  token: string;
}

const {
  JWT_COOKIE_KEY,
  TOKEN,
} = AppConstants;

@autoBindMethods
class JwtAuthenticatorClass implements IAuthenticator {
  @observable public jwt: IJwt | null = null;
  @observable public hasValidToken = false;

  private transport: ITransport;

  constructor (transport: ITransport) {
    this.transport = transport;
    this._load();

    window.setInterval(this.updateTokenValidity, TOKEN.VALIDITY_CHECK_MS);
    window.setInterval(this.refreshAuthentication, TOKEN.REFRESH_DELTA_MS);

    this.updateTokenValidity();

    if (!this.hasValidToken) {
      this.jwt = null;
    }
  }

  get isAuthenticated () {
    return this.hasValidToken;
  }

  public updateTokenValidity () {
    this.hasValidToken = !!this.jwt && !!this.jwt.token && this.jwt.expires > +new Date();
  }

  public async prepareInvoke () {
    if (!this.jwt || !this.jwt.token) {
      if (has(AppState, 'SessionStore.logout')) {
        AppState.SessionStore.logout();
      }
      return false;
    }

    if (this.jwt.expires < +new Date()) {
      // token requires refresh
      await this.refreshAuthentication();
    }

    return true;
  }

  public getAuthorizationHeader () {
    // isAuthenticated should have been called before this function is called
    // istanbul ignore next
    if (!this.jwt) { return ''; }
    return `JWT ${this.jwt.token}`;
  }

  public handleUnauthorized () {
    AppState.SessionStore.logout();
  }

  public async setUserFromRepresentatives (newJwt: any) {
    const representatives = await this.transport.get('/funder-staff/')
    , user = representatives.find((i: { id: any; }) => i.id === newJwt.user.id) || null;

    newJwt.user.permissions = user ? user.permissions : [];
    newJwt.user.groups = user ? user.groups : [];

    AppState.FunderStore.representatives = representatives;
    AppState.SessionStore.setUser(newJwt.user);
  }

  @action
  public async getAuthentication (options: any) {
    options.protected = false;

    const newJwt = await this.transport.post('/auth/jwt/', options);

    this.setAuthentication(newJwt);

    await this.setUserFromRepresentatives(newJwt);
  }

  @action
  public async refreshAuthentication () {
    if (!this.jwt) {
      return;
    }

    // tslint:disable-next-line no-console
    console.log('JwtAuthenticator :: Refreshing token');

    try {
      const newJwt = await this.transport.post('/auth/jwt/refresh/', {
        data: { token: this.jwt.token },
        protected: false,
      });

      this.setAuthentication(newJwt);

      await this.setUserFromRepresentatives(newJwt);
    }
    catch (error) {
      // tslint:disable-next-line no-console
      console.log('JwtAuthenticator :: Error refreshing token', get(error, 'response'));

      if (get(error, 'response.status') === httpStatus.BAD_REQUEST) {
        // tslint:disable-next-line no-console
        console.warn('JwtAuthenticator :: Could not refresh token, logging out.');

        AppState.SessionStore.logout();
      }
    }
  }

  @action
  public setAuthentication ({ token }: { token: string }) {
    this.jwt = {
      expires: +new Date() + TOKEN.EXPIRATION_DELTA_MS,
      token,
    };

    this.hasValidToken = true;

    this._save();
  }

  @action
  public clearAuthentication () {
    // tslint:disable-next-line no-console
    console.log('JwtAuthenticator :: Clearing token');

    this.jwt = null;
    this.hasValidToken = false;

    Storage.removeCookie(JWT_COOKIE_KEY);
  }

  public addAuthenticationToUrl (url: string) {
    if (typeof url !== 'string') {
      throw new TypeError('Invalid argument \'url\', expected string.');
    }

    if (!this.jwt || !this.jwt.token) {
      AppState.SessionStore.logout();
      throw new TypeError('Invalid token, logging out.');
    }

    const urlClean = url.replace(/&?jwt=[^&$]+/i, ''); // clean up existing token
    return urlClean.indexOf('?') === -1
      ? `${urlClean}?jwt=${this.jwt.token}`
      : `${urlClean}&jwt=${this.jwt.token}`;
  }

  public _load () {
    const snapshot = Storage.getCookie(JWT_COOKIE_KEY);
    if (!snapshot) {
      return;
    }

    this.jwt = snapshot.jwt;
  }

  public _save () {
    const snapshot = { jwt: this.jwt }
      , options = this.jwt ? { expires: new Date(this.jwt.expires) } : {}
      ;

    Storage.setCookie(JWT_COOKIE_KEY, snapshot, options);
  }
}

export default JwtAuthenticatorClass;
