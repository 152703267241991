import { action, computed, observable, toJS } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { pick, get } from 'lodash';
import axios from 'axios';
import '@datadog/browser-rum/bundle/datadog-rum';

// IE fix (window.location.origin)
import 'location-origin';

import { Freshchat, FormattingUtils, Storage } from '../utils';
import { AppConstants } from '../constants';
import {
  IDocument,
  ITransport,
  IWindow,
} from '../interfaces';
import FunderStoreClass from './FunderStoreClass';

const { toKey } = FormattingUtils;

interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  groups: string[];
  permissions: string[];
}

const SNAPSHOT_KEY = 'store-SessionStore';

const SNAPSHOT_ATTRIBUTES = [
  'impostor',
  'isSessionHijacked',
  'isStoreReady',
  'passwordResetLogin',
  'user',
];

const { MIGHTY_SERVICE_TIERS } = AppConstants;

@autoBindMethods
class SessionStore {
  public transport: ITransport;
  public Freshchat: Freshchat | null = null;
  public FunderStore: FunderStoreClass;

  @observable public isStoreReady = true;

  @observable public error: { login?: Error } = {};
  @observable public hasRegistryAccount: boolean | null = null;
  @observable public impostor: string | null = null;
  @observable public isNewVersionAvailable = false;
  @observable public isSessionHijacked = false;
  @observable public passwordResetLogin = false;
  @observable public user: IUser | null = null;

  constructor (FunderStore: FunderStoreClass, transport: ITransport) {
    this.FunderStore = FunderStore;
    this.transport = transport;
    this.load();

    // if the api has a valid token, attempt to refresh
    // only then is this store deemed ready
    if (this.isLoggedIn) {
      this.isStoreReady = false;
      this.transport.refreshAuthentication()
        .then(() => {
          this.isStoreReady = true;
        });
    }
    else {
      this.logout();
      this.isStoreReady = true;
    }

    setInterval(this.checkForNewVersion, AppConstants.APP_UPDATE_CHECK_INTERVAL);
  }

  private get window () {
    return window as unknown as IWindow;
  }

  @action
  public load () {
    const snapshot = pick(Storage.get(SNAPSHOT_KEY), SNAPSHOT_ATTRIBUTES);
    if (!snapshot) { return; }

    Object.entries(snapshot as any).forEach(([key, val]) => {
      (this as { [key: string]: any })[key] = val;
    });
  }

  public save () {
    const snapshot = pick(toJS(this), SNAPSHOT_ATTRIBUTES);
    Storage.set(SNAPSHOT_KEY, snapshot);
  }

  @computed
  get isLoggedIn () {
    return this.transport.isAuthenticated;
  }

  @computed
  get isGuest () {
    return !this.user;
  }

  @computed
  get isMightyFreeUser () {
    return MIGHTY_SERVICE_TIERS.free === get(this.FunderStore, 'funder.mighty_service_tier');
  }

  @action
  public async hijackSessionWithToken ({ token, impostor }: { token: string, impostor: string }) {
    try {
      this.logout();

      this.transport.setAuthentication({ token });
      await this.transport.refreshAuthentication();

      this.isSessionHijacked = true;
      this.impostor = impostor;

      await this.FunderStore.fetchRepresentativesAndFunderInfo();
    }
    catch (err) {
      // tslint:disable-next-line no-console
      console.error('SessionStore :: Error hijacking session', err);
    }

    this.save();
  }

  @action
  public async checkForNewVersion () {
    if (this.isNewVersionAvailable) {
      // no need to keep checking
      return;
    }

    try {
      const stats = await axios({method: 'get', url: `${window.location.origin}/stats.json`});
      if (stats.data.jsHash !== this.window.Mighty.APP_HASH) {
        this.isNewVersionAvailable = true;
      }
    }
    catch (err) {
      // swallow, not a big deal
    }
  }

  @action
  public setUser (user: IUser) {
    // tslint:disable-next-line no-console
    console.log('SessionStore :: Setting user');

    this.user = user;

    if (this.user?.id && !this.isSessionHijacked) {
      if (this.window.DD_RUM) {
        this.window.DD_RUM.setUser({
          email: this.user.email,
          id: this.user.id,
          name: `${this.user.first_name} ${this.user.last_name}`,
        });

        this.window.DD_RUM.startSessionReplayRecording();
      }
      if (this.FunderStore.funderIsReady && this.window.analytics) {
        this.FunderStore.funderIsReady.then(() => {
          this.identifyUser();

          this.Freshchat = new Freshchat(this, this.transport);
        });
      }
    }

    this.save();
  }

  @action
  public async identifyUser () {
    if (!this.user) { return; }

    const traits: { [key: string]: any } = {
        email: this.user.email,
        groups: this.user.groups,
        name: `${this.user.first_name} ${this.user.last_name}`,
      }
      , funder = get(this.FunderStore, 'funder')
      , funderStaff = get(this.FunderStore, 'funderStaff');

    if (funder) {
      traits.company = {
        activated_at: funder.activated_on,
        does_medical_funding: this.FunderStore.doesMedicalFunding,
        does_plaintiff_funding: this.FunderStore.doesPlaintiffFunding,
        id: funder.id,
        name: funder.name,
      };
    }

    if (funderStaff) {
      traits.phone = funderStaff.phone_number;
    }

    if (!this.isSessionHijacked) {
      // istanbul ignore next
      this.window.analytics.identify(this.user.id, traits);
    }
  }

  @action
  public async login (data: { username: string, password: string }) {
    if (typeof data !== 'object') {
      throw new Error('Invalid argument \'data\', expected type object.');
    }
    else if (!data.username) {
      throw new Error('Invalid argument \'data.username\', expect non-empty string.');
    }
    else if (!data.password) {
      throw new Error('Invalid argument \'data.password\', expect non-empty string.');
    }

    try {
      await this.transport.getAuthentication({ data });
      delete this.error.login;
    }
    catch (err) {
      this.error.login = err;
    }
  }

  @action
  public logout () {
    this.transport.clearAuthentication();

    if (this.window.analytics) {
      this.window.analytics.reset();
    }

    this.user = null;
    this.isSessionHijacked = false;
    this.impostor = null;
    this.hasRegistryAccount = null;

    this.save();
  }

  @action
  public addAuthenticationToUrl (url: string) {
    return this.transport.addAuthenticationToUrl(url);
  }

  public async requestPasswordReset (email: string) {
    return (await this.transport.post('/auth/password/reset/', {
      data: { email },
      protected: false,
    }));
  }

  public getDocumentViewUrl (document: IDocument) {
    const { download_url } = document
      , url = `${download_url}${toKey({dl: 0})}`;

    return this.addAuthenticationToUrl(url);
  }

  // On a 400 response, axios throws an error
  // resetPassword makes a POST request to the MightyApi
  // If the new_password passes validation, user is routed to login
  // MightyApi responds with a 400 if the new_password fails validation
  // We catch the 400 response as an error, and return the error messages
  // The error messages are rendered by PasswordResetPage
  @action
  public async resetPassword (data: object) {
    let errors: string[] = [];
    try {
      await this.transport.post('/auth/password/reset/confirm/', { data, protected: false });
      this.passwordResetLogin = true;
      return errors;
    }
    catch (err) {
      errors = err.response.data.new_password || err.response.data.non_field_errors;
      return errors;
    }
  }

  @action
  public async requestPasswordSet (data: object) {
    await this.transport.post('/auth/password/set/', { data });
  }

  // istanbul ignore next
  public trackEvent (eventType: string, additional = {}) {
    if (this.isSessionHijacked) {
      return;
    }

    // need to try/catch here to avoid running into this bug in via segment https://github.com/segmentio/myth/issues/149
    try {
      if (this.window.analytics && this.window.analytics.track) {
        this.window.analytics.track(eventType, { ...additional, userId: get(this.user, 'id') });
      }
    }
    catch (e) {
      // tslint:disable-next-line no-console
      console.error(e);
    }
  }

  public userHasPermission (permission: string) {
    return !!this.user && this.user.permissions.includes(permission);
  }

  public async fetchRegistryAccounts () {
    if (this.hasRegistryAccount === null) {
      const registryAccounts = await this.transport.get(`${this.window.Mighty.API_HOST}/api/registry/v1/accounts-auth/`);
      this.hasRegistryAccount = !!registryAccounts.results.length;
    }
  }
}

export default SessionStore;
