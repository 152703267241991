import React, { Component } from 'react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { toJS, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import ReactTable from 'react-table';
import { get } from 'lodash';
import cx from 'classnames';
import { browserHistory } from 'react-router';

import {
  Button,
  Col,
  Row,
  Well,
} from 'react-bootstrap';

import {
  Icon,
  Link,
  Page,
} from '../../common';

import ClientsClass from '../../../clients/ClientsClass';
import SmartBool from '../../../utils/SmartBool';
import { FormModal } from '../../../lib/mighty-fields';
import { FormattingUtils, TableRenderingUtils } from '../../../utils';

import DataAdminHeader from '../DataAdminHeader';
import { CREATE_IMPORT } from './FieldSets';
import Loader from '../../common/Loader';
import { IMPORT_STEPS } from './Steps';
import { getOrDefault } from '@mighty-justice/utils';

const { varToLabel } = FormattingUtils;
const { renderDate } = TableRenderingUtils;

const COLUMNS = [
    {
      accessor: 'label',
      Header: 'Label',
    },
    {
      accessor: 'configuration_label',
      Header: 'Template',
    },
    {
      accessor: 'last_status_update.status',
      Cell: (item: any) => {
        const RED_STATES = [
            IMPORT_STEPS.FAILED_VALIDATION.key,
            IMPORT_STEPS.FAILED_VALIDATION_LENGTH.key,
            IMPORT_STEPS.PREVIEW_REJECTED.key,
            IMPORT_STEPS.FAILED_TEST_COMMIT.key,
            IMPORT_STEPS.FAILED_COMMIT.key,
            IMPORT_STEPS.FAILED_REVERT.key,
            IMPORT_STEPS.FAILED_SPLIT.key,
          ]
          , GREEN_STATES = [
            IMPORT_STEPS.COMMITTED.key,
            IMPORT_STEPS.PASSED_POST_COMMIT_PROCESSING.key,
            IMPORT_STEPS.SPLIT.key,
          ]
          , classNames = cx(
          'ellipsis',
          {'text-red': RED_STATES.includes(item.value)},
          {'text-green': GREEN_STATES.includes(item.value)},
        );

        return <h4 className={classNames}>{getOrDefault(item.value && varToLabel(item.value))}</h4>;
      },
      Header: 'Status',
    },
    {
      accessor: 'created_at',
      Cell: renderDate,
      Header: 'Date Created',
    },
  ];

interface IInjected {
  Clients: ClientsClass;
}

@inject('Clients')
@autoBindMethods
@observer
class ImportsPage extends Component {
  private isLoading = new SmartBool(true);
  private showingNew = new SmartBool(false);
  @observable private imports = [];

  private get injected () {
    return this.props as IInjected;
  }

  public componentDidMount () {
    this.list();
  }

  private get client () {
    return this.injected.Clients.imports;
  }

  private async list () {
    const response = await this.isLoading.until(this.client.list());
    this.imports = get(response, 'results', []);
  }

  private async onCreate (data: object) {
    const importObj = await this.injected.Clients.imports.createForm(data)
      , pathname = `/data-admin/imports/${importObj.id}`;
    browserHistory.push({ pathname });
  }

  private getTdProps (_state: any, rowInfo: object, _column: any, _instance: any) {
    const importId = get(rowInfo, 'original.id')
      , to = importId && { pathname: `/data-admin/imports/${importId}` };

    return { to };
  }

  private tdComponent ({ className, children, to, ...rest }: { [key: string]: any }) {
    return <Link className={cx(className, 'rt-td')} to={to} {...rest}>{children}</Link>;
  }

  public renderWell () {
    if (this.isLoading.isTrue) {
      return <Loader className='bloc-loader' />;
    }

    if (this.imports.length < 1) {
      return (
        <React.Fragment>
          <h3>Imports</h3>
          <Button className='btn-add' bsStyle='link' onClick={this.showingNew.setTrue}>
            <Icon type='plus-square-o' /> New Import
          </Button>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>
          <Button bsStyle='link' className='pull-right' onClick={this.showingNew.setTrue}>
            <Icon type='plus-square-o' /> New Import
          </Button>
          Imports
        </h3>
        <ReactTable
          className={cx('-highlight')}
          columns={COLUMNS}
          data={toJS(this.imports)}
          getTdProps={this.getTdProps}
          loading={this.isLoading.isTrue}
          minRows={0}
          pageSize={this.imports.length}
          resizable={false}
          showPagination={false}
          TdComponent={this.tdComponent}
        />
      </React.Fragment>
    );
  }

  public render () {
    return (
      <Page name='imports' type='detail'>
        <Helmet title='Imports' />
        <Page.Content>
          <DataAdminHeader title='Imports' />
          <div className='main'>
            <Row>
              <Col className='col-main' xs={12}>
                <Well className='maxwidth-lg center-block'>
                  {this.renderWell()}
                </Well>
              </Col>
            </Row>
          </div>

          {this.showingNew.isTrue && (
            <FormModal
              fieldSets={CREATE_IMPORT}
              onClose={this.showingNew.setFalse}
              onSave={this.onCreate}
              title='New Import'
            />
          )}
        </Page.Content>
      </Page>
    );
  }
}

export default ImportsPage;
