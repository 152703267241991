import autoBindMethods from 'class-autobind-decorator';
import { cloneDeep } from 'lodash';

import FormDataUtils from '../utils/FormDataUtils';
import { ITransport } from '../interfaces';

@autoBindMethods
class Client {
  public endpoint: string;
  protected transport: any;

  constructor (endpoint: string, transport: ITransport) {
    if (!endpoint.endsWith('/')) {
      throw new Error('CANNOT CREATE CLIENT -- ENDPOINT MUST END WITH A TRAILING SLASH');
    }
    this.endpoint = endpoint;
    this.transport = transport;
  }

  get endpointId () {
    return `${this.endpoint}:id/`;
  }

  get endpointAction () {
    return `${this.endpoint}:action/`;
  }

  get endpointActionDetail () {
    return `${this.endpointId}:action/`;
  }

  public async list (options: any = {}) {
    return (await this.transport.get(this.endpoint, cloneDeep(options)));
  }

  public async listAll (options: any = {}) {
    return (await this.transport.getAll(this.endpoint, cloneDeep(options)));
  }

  public async retrieve (id: string, options: any = {}) {
    return (this.transport.get(this.endpointId, { ...options, urlParams: { id }}));
  }

  public async update (id: string, data: any) {
    return (await this.transport.patch(this.endpointId, { data, urlParams: { id } }));
  }

  public async updateNoId (data: any) {
    return (await this.transport.patch(this.endpoint, { data }));
  }

  public async updateForm (id: string, data: any) {
    return (await this.update(id, FormDataUtils.toForm(data)));
  }

  public async updateBulk (data: any) {
    return (await this.transport.patch(this.endpoint, { data }));
  }

  public async create (data?: any) {
    return (await this.transport.post(this.endpoint, { data }));
  }

  public async createForm (data: any) {
    return (await this.create(FormDataUtils.toForm(data)));
  }

  public async delete (id: string) {
    await this.transport.delete(this.endpointId, { urlParams: { id }});
  }

  public async getDocument (url: string) {
    await this.transport.getDocument(url);
  }

  public async createAction (action: string, data?: object) {
    return (await this.transport.post(this.endpointAction, { data, urlParams: { action } }));
  }

  public async patchAction (id: string, action: string, data?: object) {
    return (await this.transport.patch(this.endpointActionDetail, { data, urlParams: { id, action } }));
  }

  public async putAction (id: string, action: string, data?: object) {
    return (await this.transport.put(this.endpointActionDetail, { data, urlParams: { id, action } }));
  }

  public async listAction (action: string, data: any = {}) {
    return (await this.transport.get(this.endpointAction, { ...data, urlParams: { action } }));
  }

  public async bulkPatchAction (action: string, data?: object) {
    return (await this.transport.patch(this.endpointAction, { data, urlParams: { action } }));
  }

  public async loadMore (nextPageURL: string) {
    return (await this.transport.get(nextPageURL));
  }

  public async counts (data: any = {}) {
    return (await this.listAction('counts', data));
  }
}

export default Client;
