import autoBindMethods from 'class-autobind-decorator';
import httpStatus from 'http-status-codes';
import { get } from 'lodash';
import { ITransport, IWindow } from '../interfaces';
import SessionStoreClass from '../stores/SessionStoreClass';
import Client from '../clients/Client';

interface IResponse {
  data?: any;
  status: number;
}

@autoBindMethods
class Freshchat {
  private FreshchatClient: Client;
  private SessionStore: SessionStoreClass;

  public user: any;

  constructor (SessionStore: SessionStoreClass, transport: ITransport) {
    this.SessionStore = SessionStore;
    this.FreshchatClient = new Client('/freshchat/', transport);

    if (this.widget && !SessionStore.isSessionHijacked && SessionStore.isMightyFreeUser) {
      this.init();
    }
  }

  private get window () {
    return window as unknown as IWindow;
  }

  private get widget () {
    return this.window.fcWidget;
  }

  private async fetchRestoreId () {
    const data = await this.FreshchatClient.list();
    return get(data, 'results[0].restore_id', null);
  }

  private async init () {
    const { user } = this.SessionStore;

    // istanbul ignore next
    if (!user) { return; }

    const restoreId = await this.fetchRestoreId();

    const userProperties = {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      siteId: 'mighty-v2',
    };

    this.widget.init({
      externalId: user.id,
      host: 'https://wchat.freshchat.com',
      restoreId,
      siteId: 'mighty-v2',
      tags: ['user-type-free'],
      token: this.window.Mighty.FRESHCHAT_KEY,
    });

    this.widget.user.get((userResponse: IResponse) => {
      this.user = get(userResponse, 'data');

      if (userResponse.status !== httpStatus.OK) {
        this.widget.user.setProperties(userProperties);

        this.widget.on('user:created', (createdResponse: IResponse) => {
          const data = get(createdResponse, 'data');
          if (createdResponse.status === httpStatus.OK && data.restoreId) {
            this.FreshchatClient.create({id: user.id, restore_id: data.restoreId});
          }
        });
      }
      else if (!restoreId && this.user.restoreId) {
        this.FreshchatClient.create({id: user.id, restore_id: this.user.restoreId});
      }
    });
  }
}

export default Freshchat;
