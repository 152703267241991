import React, { Component } from 'react';
import Helmet from 'react-helmet';
import autoBindMethods from 'class-autobind-decorator';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

import {
  Alert,
  Button,
  Col,
  Grid,
  Row,
  Well,
} from 'react-bootstrap';

import { Page } from '../common';
import { Form } from '../common-formsy';

import FormManager from '../../lib/mighty-fields/FormManager';
import {
  EditableCard,
  FormFieldSets,
  IFieldSetPartial,
} from '../../lib/mighty-fields';

import {
  FormattingUtils,
  SmartBool,
} from '../../utils';

import { AppConstants } from '../../constants';

import { FunderStoreClass, SessionStoreClass } from '../../stores';
import { createGuardedContainer } from '../../containers';

const { formatPhoneNumber } = FormattingUtils;
const { PERMISSIONS } = AppConstants;

const FIELD_SETS: IFieldSetPartial[] = [
  [
    {field: 'current_password', type: 'password'},
    {field: 'new_password', type: 'password'},
    {field: 're_new_password', label: 'Confirm New Password', type: 'password'},
  ],
];

const ACCOUNT_INFO_FIELD_SETS: IFieldSetPartial[] = [
  [
    {field: 'name', required: true},
  ],
];

const PERSONAL_INFO_FIELD_SETS: IFieldSetPartial[] = [
  [
    {field: 'first_name', required: true},
    {field: 'last_name', required: true},
    {field: 'email', required: true, type: 'email'},
    {field: 'phone_number', type: 'phone'},
  ],
];

interface IInjected {
  FunderStore: FunderStoreClass;
  SessionStore: SessionStoreClass;
}

interface IError {
  [key: string]: string;
  message: string;
}

interface IModel {
  [key: string]: string;

  confirm_password: string;
  new_password: string;
  re_new_password: string;
}

@inject('FunderStore', 'SessionStore')
@autoBindMethods
@observer
class MyAccountPage extends Component {
  @observable private showChangePasswordSuccess = new SmartBool(false);
  private formManager: FormManager;

  constructor (props: IInjected) {
    super(props);

    this.formManager = new FormManager({
      fieldSets: FIELD_SETS,
      onSave: this.onSave,
      onSuccess: this.onSuccess,
    });
  }

  private get injected () {
    return this.props as IInjected;
  }

  private async onSave (formData: object) {
    const data = formData as IModel
        , { SessionStore } = this.injected;

    this.showChangePasswordSuccess.setFalse();
    await SessionStore.requestPasswordSet(toJS(data));
  }

  private onSuccess () {
    this.showChangePasswordSuccess.setTrue();
    this.formManager.reset();
  }

  private handleSuccessDismiss () { this.showChangePasswordSuccess.setFalse(); }

  private renderChangePassword () {
    return (
      <Well>
        <h3>Change Password</h3>
        <Form
          id='form-change-password'
          {...this.formManager.formProps}
        >
          {this.showChangePasswordSuccess.isTrue &&
            <Alert bsStyle='success' className='lowercase' onDismiss={this.handleSuccessDismiss}>
              <p>Your new password has been set successfully.</p>
            </Alert>
          }

          {!!this.formManager.errorMessages.length &&
            (<Alert bsStyle='danger' className='lowercase'>
              <ul>
                {this.formManager.errorMessages.map((error: IError, idx) => <li key={idx}>{error.message}</li>)}
              </ul>
            </Alert>)
          }

          <FormFieldSets
            fieldSets={FIELD_SETS}
            {...this.formManager.formGroupsProps}
          />

          <Button
            bsSize='small'
            bsStyle='primary'
            disabled={this.formManager.submitDisabled()}
            type='submit'
          >
            Set new password
          </Button>
        </Form>
      </Well>
    );
  }

  private renderCommunicationNumbers () {
    const { FunderStore: { funder } } = this.injected;

    return (
      <Well>
        <h3>Fax &amp; SMS phone number</h3>
        <div>{funder && formatPhoneNumber(funder.twilio_phone_number)}</div>
      </Well>
    );
  }

  private renderGuardedAccountInfoWell () {
    const GuardedAccountInfoWell = createGuardedContainer({
      enabledComponent: EditableCard,
      permissionName: PERMISSIONS.CHANGE_FUNDER,
    })
      , { FunderStore } = this.injected
      , { funder } = FunderStore
      ;

    return (
      <GuardedAccountInfoWell
        fieldSets={ACCOUNT_INFO_FIELD_SETS}
        model={funder || {}}
        onSave={FunderStore.updateAccountInfo}
        title='Account Info'
      />
    );
  }

  /* tslint:disable no-magic-numbers */
  public render () {
    const { FunderStore } = this.injected
      , { funder, funderStaff } = FunderStore
      ;

    return (
      <Page name='my-account'>
        <Helmet title='My Account' />
        <Page.Content>
          <Grid>
            <Row className='row-padding'>
              <Col sm={6} lg={5}>
                {this.renderGuardedAccountInfoWell()}
                <EditableCard
                  fieldSets={PERSONAL_INFO_FIELD_SETS}
                  model={funderStaff || {}}
                  onSave={FunderStore.updateFunderStaff}
                  title='Personal Info'
                />
                {this.renderChangePassword()}
                {funder && funder.twilio_phone_number && this.renderCommunicationNumbers()}
              </Col>
            </Row>
          </Grid>
        </Page.Content>
      </Page>
    );
  }
}

export default MyAccountPage;
