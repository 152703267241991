import { get } from 'lodash';
import httpStatus from 'http-status-codes';

export function errorResponseStatus (error: Error) {
  return get(error, 'response.status');
}

export function errorResponseStatusMatches (error: Error, status: any) {
  return errorResponseStatus(error) === status;
}

export function isTransportError (error: Error) {
  return !!get(error, 'isTransportError');
}

export function isTransportErrorNotFound (error: Error) {
  return errorResponseStatusMatches(error, httpStatus.NOT_FOUND);
}

export function isTransportErrorUnauthorized (error: Error) {
  return errorResponseStatusMatches(error, httpStatus.UNAUTHORIZED);
}

export function isTransportErrorNoResponse (error: Error) {
  return isTransportError(error) && !get(error, 'response');
}

export function transportErrorToastMessage (error: Error, options: object) {
  if (isTransportErrorNoResponse(error)) {
    return `
       The Mighty server appears to be unreachable.
       You may want to check your internet connection.
    `;
  }

  const errorStatus = errorResponseStatus(error);

  if ([httpStatus.UNAUTHORIZED, httpStatus.FORBIDDEN].includes(errorStatus)) {
    return `
      There was a problem authenticating with Mighty.
      Make sure you are signed in or try refreshing the page.
    `;
  }

  const writeActionWarning = (
    ['POST', 'PATCH', 'DELETE'].includes(get(options, 'method'))
      ? 'Your last action may have not been completed.\n'
      : ''
  );

  if ([httpStatus.INTERNAL_SERVER_ERROR, httpStatus.GATEWAY_TIMEOUT].includes(errorStatus)) {
    return `
      The Mighty server responded with an error.
      ${writeActionWarning}
      This error has been automatically reported to our development team.
    `;
  }

  // These errors should be caught by the parent
  if ([httpStatus.BAD_REQUEST, httpStatus.NOT_FOUND].includes(errorStatus)) {
    return;
  }

  return `
    An error occurred communicating with the Mighty server.
    ${writeActionWarning}
  `;
}
