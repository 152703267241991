import { action, observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { keys, sortBy, get, find, filter } from 'lodash';

import { AppConstants } from '../constants';

const { REGISTRY_SHARING_ELIGIBILITY_OPTIONS } = AppConstants;

import {
  ITransport,
} from '../interfaces';

interface IDebouncer {
  flush: () => void;
}

interface IOption {
  name: string;
  value: any;
}

interface ICaseTypeOption {
  name: string;
  id: any;
}

interface IDocumentTypeOption {
  description: string;
  id: number;
  name: string;
}

interface IApplicationStatusTransitionReason {
  id: string;
  reason: {
    short_code: string,
  };
}

const BLANK_OPTION: IOption = { value: null, name: '' }
  , BLANK_CASE_TYPE_OPTION: ICaseTypeOption = { id: null, name: '' };

@autoBindMethods
class OptionsStoreClass {
  private transport: ITransport;

  @observable public loading = true;

  @observable public applicationStatusOptions: IOption[] = [];
  @observable public applicationStatusTransitionReasons: IApplicationStatusTransitionReason[] = [];
  @observable public caseMedicalStatusOptions: IOption[] = [];
  @observable public caseTrackingAttributeOptions: IOption[] = [];
  @observable public caseTrackingFollowUpDateStatusOptions: IOption[] = [];
  @observable public caseTrackingStatusOptions: IOption[] = [];
  @observable public caseTypeOptions: ICaseTypeOption[] = [];
  @observable public documentTypeOptions: IDocumentTypeOption[] = [];
  @observable public genericLienTypes: Array<{ id: string, name: string }> = [];
  @observable public importConfigurations: Array<{ id: string, name: string }> = [];
  @observable public leadSourceCategoryOptions: IOption[] = [];
  @observable public returnTypes: IOption[] = [];
  @observable public stateOptions: IOption[] = [];
  @observable public warningReasons: { [key: string]: string } = {};

  public debouncers: Set<IDebouncer> = new Set([]);

  public sexOptions: IOption[] = [
    {name: 'Male', value: 'M'},
    {name: 'Female', value: 'F'},
  ];

  public yesOrNoOptions: IOption[] = [
    {name: 'Yes', value: true},
    {name: 'No', value: false},
  ];

  public methodOfCommunicationOptions: IOption[] = [
    'Cell',
    'Email',
    'Fax',
    'Phone',
  ].map(v => ({ name: v, value: v }));

  public relationshipToCaseOptions = [
    {name: 'Attorney', value: 'Attorney'},
    {name: 'Paralegal/Case Manager', value: 'Paralegal/Case Manager'},
    {name: 'Other', value: 'Other'},
  ];

  public registrySharingEligibilityOptions: IOption[] = REGISTRY_SHARING_ELIGIBILITY_OPTIONS;

  // istanbul ignore next
  constructor (transport: ITransport) {
    this.transport = transport;
  }

  public async search (endpoint: string, search: string) {
    const params = { search }
      , response = await this.transport.get(endpoint, { params });
    return response.results;
  }

  public registerDebouncer (func: IDebouncer) {
    this.debouncers.add(func);
  }

  public removeDebouncer (func: IDebouncer) {
    this.debouncers.delete(func);
  }

  public async flushDebouncers () {
    await Promise.all(Array.from(this.debouncers).map(debounced => debounced.flush()));
  }

  public async fetchLeadSourceNameOptions (category: string, search: string) {
    return (await this.transport.get('/lead-sources/', { params: { category, search } })).results;
  }

  @action
  public async fetchGenericLienSubtypeOptions () {
    const subtypes = await this.transport.get('/generic-lien-subtypes/') as Array<{ name: string, label: string }>;
    this.genericLienTypes = subtypes.map(subtype => ({id: subtype.name, name: subtype.label}));
  }

  @action
  public async fetchImportConfigurationOptions () {
    const configs = await this.transport.get('/funder-import-configurations/');
    this.importConfigurations = configs.map((config: {id: string, label: string}) => ({id: config.id, name: config.label}));
  }

  @action
  public async fetchLeadSourceCategoryOptions () {
    const categories = await this.transport.get('/lead-sources/categories/')
      , cleanedData = keys(categories).map(key => {
        return {name: categories[key], value: key };
      });
    this.leadSourceCategoryOptions = sortBy(cleanedData, 'value');
  }

  @action
  public async fetchOptions (key: string, endpoint: string) {
    const response = await this.transport.get(endpoint);
    (this as any)[key] = response;
  }

  @action
  public async fetchEnum (key: string, endpoint: string) {
    const response = await this.transport.get(endpoint);
    (this as any)[key] = Object.keys(response).map(id => ({ id, name: response[id] }));
  }

  @action
  public async prepareOptionStore () {
    await Promise.all([
      this.fetchEnum('returnTypes', '/return-types/'),
      this.fetchGenericLienSubtypeOptions(),
      this.fetchImportConfigurationOptions(),
      this.fetchLeadSourceCategoryOptions(),
      this.prepareCaseTrackingUpdateOptions(),
      this.prepareIntake(),
      this.fetchOptions('applicationStatusOptions', '/application-statuses/'),
      this.fetchOptions('applicationStatusTransitionReasons', '/application-status-transition-reasons/'),
      this.fetchOptions('caseTrackingFollowUpDateStatusOptions', '/cases/tracking-follow-up-date-status/'),
      this.fetchOptions('documentTypeOptions', '/document-types/'),
      this.fetchOptions('warningReasons', '/warning-reasons/'),
    ]);
    this.loading = false;
  }

  @action
  public async prepareIntake () {
    await Promise.all([
      this.fetchOptions('caseTypeOptions', '/case-types/'),
      this.fetchOptions('stateOptions', '/states/'),
    ]);
    this.loading = false;
  }

  @action
  public async prepareCaseTrackingUpdateOptions () {
    await Promise.all([
      this.fetchOptions('caseTrackingAttributeOptions', '/cases/tracking-attributes/'),
      this.fetchOptions('caseTrackingStatusOptions', '/cases/tracking-statuses/'),
      this.fetchOptions('caseMedicalStatusOptions', '/cases/medical-statuses/'),
    ]);
  }

  @action
  public async prepareCaseUpdateRequestForm () {

    await Promise.all([
      this.fetchOptions('caseTrackingStatusOptions', '/cases/tracking-statuses/'),
      this.fetchOptions('caseMedicalStatusOptions', '/cases/medical-statuses/'),
    ]);
    this.loading = false;
  }

  public getStateOptionByCode (code: string) {
    return this.getFirstOptionByKey(this.stateOptions, 'code', code) as IOption || BLANK_OPTION ;
  }

  public formatStateName (obj?: { address?: { state: string } }) {
    if (!obj || !get(obj, 'address.state')) { return null; }
    return this.getStateOptionByCode(obj!.address!.state).name;
  }

  public getCaseTypeOptionById (id: string) {
    return this.getFirstOptionByKey(this.caseTypeOptions, 'id', id) as ICaseTypeOption || BLANK_CASE_TYPE_OPTION;
  }

  public getApplicationTranisitionReasonsByStatus (status: string) {
    return this.getAllOptionsByKey(this.applicationStatusTransitionReasons, 'status', status) || BLANK_OPTION;
  }

  public getApplicationTranistionReasonByShortCode (shortCode: string) {
    return this.applicationStatusTransitionReasons.find((o) => o.reason.short_code === shortCode);
  }

  public getFirstOptionByKey (options: object[], key: string, value: any) {
    return find(options, { [key]: value });
  }

  public getAllOptionsByKey (options: object[], key: string, value: any) {
    return filter(options, { [key]: value });
  }
}

export default OptionsStoreClass;
