import store from 'store';
import Cookie from 'js-cookie';

import { IWindow } from '../interfaces';

const API_HOST = (window as unknown as IWindow).Mighty.API_HOST
  , HOST = API_HOST ? new URL(API_HOST).host : undefined
  , COOKIE_PROPS = { domain: HOST, path: '/' };

export default {
  get (key: string): any {
    try {
      return JSON.parse(store.get(key));
    }
    catch (e) {
      return null;
    }
  },

  getCookie (key: string) {
    return Cookie.getJSON(key);
  },

  remove (key: string) {
    // Currently unused, but worth keeping around
    // istanbul ignore next
    store.remove(key);
  },

  removeCookie (key: string) {
    Cookie.remove(key, COOKIE_PROPS);
  },

  set (key: string, value: any) {
    store.set(key, JSON.stringify(value));
  },

  setCookie (key: string, data: any, options: any = {}) {
    return Cookie.set(key, data, { ...COOKIE_PROPS, ...options, secure: true });
  },
};
