import URI from 'urijs';
import { has } from 'lodash';

import Storage from './Storage';
import TokenAuthenticatorClass from './TokenAuthenticatorClass';
import JwtAuthenticatorClass from './JwtAuthenticatorClass';
import { AppConstants } from '../constants';

const {
  JWT_COOKIE_KEY,
} = AppConstants;

function selectAuthenticator () {
  const urlQuery: any = URI.parseQuery(window.location.search)
    , mightyApiData = Storage.getCookie(JWT_COOKIE_KEY)
    , hasJWT = has(mightyApiData, 'jwt.token')
    ;

  // If there is a stored jwt token, return JwtAuthenticatorClass
  if (hasJWT) { return JwtAuthenticatorClass; }

  // If there is a token in the url, return TokenAuthenticatorClass
  // Cannot currently be tested: https://github.com/facebook/jest/issues/890
  // istanbul ignore next
  if (urlQuery.token) { return TokenAuthenticatorClass; }

  // If there is neither, return JwtAuthenticatorClass
  return JwtAuthenticatorClass;
}

export default selectAuthenticator;
